<!-- <div class="flex justify-between mb-5">
    <h1>
        Scratch Cards Status:
    </h1>
</div> -->
<form [formGroup]="getData">
    <div class="grid grid-cols-4 items-center gap-3 w-full">
        <div class="md:col-span-2  col-span-12 text-black font-semibold uppercase">
            Scratch Cards Status:
        </div>
        <div class="md:col-span-1  col-span-12">
            <ng-select [items]="scratchCardFilter" placeholder="Sort By" bindLabel="name" bindValue="value" class="w-full "
            formControlName="cardFilter" (change)="getCardData()" >
            </ng-select>
        </div>
    
        <div class="md:col-auto col-span-12">
            <input type="text" class=" border-gray-200 py-1 px-2 w-full border-2" placeholder="Search by customer name,mobileNo"
            (input)="search($event)"
            >
        </div>
    </div>
</form>

<div class="flex flex-col">
    <div class="overflow-x-auto shadow-md sm:rounded-lg">
        <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden ">
                <table class="min-w-full divide-y divide-gray-200 table-fixed ">
                    <thead class="bg-gray-100 ">
                        <tr>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Id</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Thmbnail Image</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Offer Image</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Offer Type</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Customer Name</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Mobile No</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Astrologers Name</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Discount on Amount</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Expire At</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Rate Per Min</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Min</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Total/Discount(%)</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Status</th>
                            <!-- <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Action</th> -->
                        </tr>
                    </thead>
                    <tbody *ngIf="cardList?.length!==0;else noData"
                        class=" bg-white divide-y divide-gray-200 ">
                        <tr *ngFor="let item of cardList?.customerList" class="hover:bg-gray-100 cursor-pointer">
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 break-words text-left">
                                {{item?.id}}
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 break-words text-left">
                                <img src={{item?.thumbnail}}  alt="offerImage" class="w-[50px] h-[50px]">
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 break-words text-left">
                                <img src={{item?.bgImage}}  alt="offerImage" class="w-[50px] h-[50px]">
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 break-words text-left">
                                {{item?.offerType}}
                            </td>

                            <td class="py-4 px-6 text-sm font-medium text-gray-500 break-words text-left">
                                {{item?.customerName}}                            
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 break-words text-left">
                                {{item?.mobileNo }}                       
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 break-words whitespace-nowrap">

                                <button *ngIf="item?.offerType!=='Discount on recharge'; else noAStroData"
                                (click)="openAstroListModal(template2);viewMoreAstroList(item?.astroDetail)"
                                class="p-1 text-blue-500 rounded  text-left"
                                >
                                    View More...
                                </button>
                                <ng-template #noAStroData>
                                    N/A
                                </ng-template>
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left ">
                                <button *ngIf="item?.rechargeDetail; else noAmountData"
                                (click)="openRechargeListModal(template3);viewMoreRechargeList(item?.rechargeDetail)"
                                    class="p-1 text-blue-500 rounded  text-left"
                                    >
                                        View More...
                                </button>
                                <ng-template #noAmountData>
                                    N/A
                                </ng-template>
                             </td>

                            <td class="py-4 px-6 text-sm font-medium text-gray-500 break-words text-left">
                                {{item?.expireTime | date:"short"}}
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left"
                                >
                                {{item?.ratePerMin }} 
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left"
                            
                                >
                                {{item?.min }} 
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left"
                            
                            >
                                {{item?.total }} 
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left"
                            >
                                {{
                                    item?.status=='1' ? 'New' : 
                                        item?.status=='2' ? 'Open' :
                                            item?.status=='3' ? 'Used' :
                                                item?.status=='4' ? 'Expired' : 
                                                    'N/A'
                                }} 
                            </td>
                        </tr>
                    </tbody>
                    <ng-template #noData>
                        <tbody>
                            <tr>
                                <td colspan="12" class="text-center" style="font-size: 14px">
                                    No data available
                                </td>
                            </tr>
                        </tbody>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>



<div class="flex justify-between p-2">
    <div>
        <input #titleInput class="border-gray-200 p-1 border-2 w-20" placeholder="Page No." (keydown.enter)="handleGoto(titleInput.value)">
        <button (click)="handleGoto(titleInput.value)"
            class="border-gray-200 p-1 border-2 bg-slate-300 hover:bg-slate-500">Go</button>
    </div>
    <div>
        <mat-paginator [length]="cardList?.totalPage" [pageSize]="1" hidePageSize="true" onchange="getScratchCardsList()" pageIndex={{pageRef}}
            (page)="onPaginateChange($event)">
        </mat-paginator>
    </div>
</div>

<div class="flex justify-center w-full">
    <div class="grid grid-cols-8 items-center gap-1 w-full text-xs">
      <div class="md:col-span-1 col-span-12">
        <div>Total Scratch Cards Created</div>
        <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
          {{summaryCount ? summaryCount.totalCards : 0 }}
        </div>
      </div>
      <div class="md:col-span-1 col-span-12">
        <div>Total New Scratch Cards</div>
        <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
            {{summaryCount ? summaryCount.newCards : 0 }}
        </div>
      </div>
      <div class="md:col-span-1 col-span-12">
        <div>Total Used Scratch Cards</div>
        <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
            {{summaryCount ? summaryCount.usedCards : 0 }}
        </div>
      </div>
      <div class="md:col-span-1 col-span-12">
        <div>Total Expired Scratch Cards</div>
        <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
            {{summaryCount ? summaryCount.expiredCards : 0 }}
        </div>
      </div>
      <div class="md:col-span-1 col-span-12 mt-4">
        <!-- <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-2 rounded" >
          Export to Excel
        </button> -->
      </div>
    </div>
</div>

<ng-template #template2>
    <div class="z-40 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
    tabindex="-1"  aria-hidden="true">
    <div class="sm:h-[calc(100%-3rem)] max-w-lg my-6 mx-auto relative w-auto pointer-events-none">
      <div
        class="max-h-full overflow-hidden border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
        <!-- <div
          class="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
          <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
            Modal title
          </h5>
          <button type="button"
            class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-bs-dismiss="modal" aria-label="Close"></button>
        </div> -->
        <div class="modal-header flex justify-between p-0 m-0">
            <div>
              <p class="font-bold text-xl">Astrologers List</p>
            </div>
            <div class="float-right">
              <button (click)="closeModal()"><mat-icon>close</mat-icon></button>
            </div>
          </div>
        <div class="flex-auto overflow-y-auto relative p-4">
          <table class="min-w-full divide-y divide-gray-200 table-fixed">
            <thead class="bg-gray-100">
            <tr>
                <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                >
                Astrologer Name
                </th>
            </tr>
            </thead>
            <tbody
            *ngIf="viewMoreAstrologerList?.length !== 0; else noData"
            class="bg-white divide-y divide-gray-200"
            >
            <tr *ngFor="let i of viewMoreAstrologerList" class="hover:bg-gray-100">

                <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
                >
                <p>{{ i?.FullName }} </p>
                </td>
            </tr>
            </tbody>
            <ng-template #noData>
            <tbody>
                <tr>
                <td colspan="12" class="text-center" style="font-size: 14px">
                    No data available
                </td>
                </tr>
            </tbody>
            </ng-template>
          </table>
        </div>
        <div
          class="flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
          <button type="button"
            class="inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
            data-bs-dismiss="modal" (click)="closeModal()">
            Close
          </button>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #template3>
    <div class="z-40 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
    tabindex="-1"  aria-hidden="true">
    <div class="sm:h-[calc(100%-3rem)] max-w-lg my-6 mx-auto relative w-auto pointer-events-none">
      <div
        class="max-h-full overflow-hidden border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
        <div class="modal-header flex justify-between p-0 m-0">
            <div>
              <p class="font-bold text-xl">Offer Amount</p>
            </div>
            <div class="float-right">
              <button (click)="closeModal()"><mat-icon>close</mat-icon></button>
            </div>
          </div>
        <div class="flex-auto overflow-y-auto relative p-4">
          <table class="min-w-full divide-y divide-gray-200 table-fixed">
            <thead class="bg-gray-100">
            <tr>
                <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                >
                Amount 
                </th>
            </tr>
            </thead>
            <tbody
            *ngIf="rechargeListMore?.length !== 0; else noData"
            class="bg-white divide-y divide-gray-200"
            >
            <tr *ngFor="let i of rechargeListMore" class="hover:bg-gray-100">

                <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
                >
                <p>{{ i?.Amount }} </p>
                </td>
            </tr>
            </tbody>
            <ng-template #noData>
            <tbody>
                <tr>
                <td colspan="12" class="text-center" style="font-size: 14px">
                    No data available
                </td>
                </tr>
            </tbody>
            </ng-template>
          </table>
        </div>
        <div
          class="flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
          <button type="button"
            class="inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
            data-bs-dismiss="modal" (click)="closeModal()">
            Close
          </button>
          <!-- <button type="button"
            class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
            Save changes
          </button> -->
        </div>
      </div>
    </div>
  </div>
</ng-template>