import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TriggerService {
  subjectNotifier: Subject<null> = new Subject<null>();
  eventEmitterNotifier: EventEmitter<null> = new EventEmitter();
  constructor() { }

  notifyAboutChange() {
    this.subjectNotifier.next(null);
    this.eventEmitterNotifier.emit();
  }
}
