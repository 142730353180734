import { Component } from '@angular/core';

@Component({
  selector: 'app-calling-feedback',
  templateUrl: './calling-feedback.component.html',
  styleUrls: ['./calling-feedback.component.css']
})
export class CallingFeedbackComponent {

}
