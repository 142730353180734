<!-- <h1 class="mb-5">Call History Report:</h1> -->
<div class="mx-auto">
    <form [formGroup]="getActivityData">
      <div class="flex justify-center w-full">
        <div class="grid grid-cols-4 items-center gap-3 w-full">
          <div class="md:col-span-1 col-span-12">
            <p class="text-xl font-bold underline tracking-widest">User Activity</p>
          </div>
          <div class="md:col-span-1 col-span-12">
            <ng-select [items]="option" placeholder="Sort By" bindLabel="name" bindValue="value" formControlName="type"
              class="w-full" (change)="fetchActivityData()">
            </ng-select>
          </div>
          <div class="md:col-auto col-span-12">
            <input type="text" class="border-2 py-1 px-2 w-full" ngxDaterangepickerMd [showCustomRangeLabel]="true"
              [(ngModel)]="selected" [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true"
              [isInvalidDate]="isInvalidDate" formControlName="StartDate" [showClearButton]="true"
              placeholder="Select date range " (change)="choosedDate($event)" />
          </div>
          <!-- <div class="md:col-span-1 col-span-12">
            <ng-select [items]="filter" placeholder="Filter By" bindLabel="name" bindValue="value"
              formControlName="sfilter" class="w-full" (change)="getCallReport()">
            </ng-select>
          </div> -->
          <div class="md:col-auto col-span-12">
            <input type="text" class="border-gray-200 py-1 px-2 w-full border-2" placeholder="search....."
              (input)="search($event)" />
          </div>
        </div>
      </div>
    </form>
  
    <div class="flex flex-col">
      <div class="overflow-x-auto shadow-md sm:rounded-lg">
        <div class="inline-block min-w-full align-middle">
          <div class="overflow-hidden">
            <table class="min-w-full divide-y divide-gray-400 table-fixed bor">
              <thead class="bg-gray-200">
                <tr>
                  <th scope="col" class="py-3 px-6 text-xs font-extrabold tracking-wider text-left text-gray-700 uppercase">
                    Customer Name
                  </th>
                  <th scope="col" class="py-3 px-6 text-xs font-extrabold tracking-wider text-left text-gray-700 uppercase">
                    Mobile No
                  </th>
                  <th scope="col" class="py-3 px-6 text-xs font-extrabold tracking-wider text-left text-gray-700 uppercase">
                    Total Acitvity
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="activityList !== null; else noData"
                class="bg-white divide-y divide-gray-200">
                <tr *ngFor="let callHistory of activityList?.activityData" class="hover:bg-gray-100 cursor-pointer">
                  <td 
                    class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                    <!-- {{ callHistory?.FirstName }} {{ callHistory?.LastName }} -->
                    <a routerLink="../mng-customers/customer-view/customer-profile"
                        [queryParams]="{type:'activity',id:callHistory?.customerid}" role="link" target="_blank"
                        class="hover:text-blue-400 hover:cursor-pointer">
                        {{
                        callHistory?.FirstName +
                        " " +
                        callHistory?.LastName
                        }}
                    </a>
                  </td>
                  <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                    {{ callHistory?.mobileno }} 
                  </td>
                  <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                    <a routerLink="../mng-customers/customer-view/customer-profile"
                        [queryParams]="{type:'activity',id:callHistory?.customerid}" role="link" target="_blank"
                        class="hover:text-blue-400 hover:cursor-pointer">
                        {{ callHistory?.count }}
                    </a>
                  </td>
                </tr>
              </tbody>
              <ng-template #noData>
                <tbody>
                  <tr>
                    <td colspan="12" class="text-center text-red-500 text-lg" >
                      N/A
                    </td>
                  </tr>
                </tbody>
              </ng-template>
  
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  <mat-paginator [length]="activityList?.totalPage" [pageSize]="1" hidePageSize="true" onchange="getData()"
    (page)="onPaginateChange($event)">
  </mat-paginator>

