<div class="flex justify-between my-5">
    <h1>
        Manage Tech Support Videos:
    </h1>
    <div>
        <button (click)="openModal(null,template)"
            class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">Add
            new Video</button>
    </div>
</div>
<div
    class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
    <ul class="flex flex-wrap -mb-px">
        <li class="mr-2">
            <a (click)="setTabOption(1, null)"
                [ngClass]="tabOption==1?'text-blue-600  border-b-2 border-blue-600 rounded-t-lg active':''"
                class="cursor-pointer inline-block p-4 rounded-t-lg hover:text-gray-600 hover:border-gray-300 ">Astrologers</a>
        </li>
        <li class="mr-2">
            <a (click)="setTabOption(2, 'customers')"
                class="cursor-pointer inline-block p-4 rounded-t-lg hover:text-gray-600 hover:border-gray-300"
                [ngClass]="tabOption==2?'text-blue-600 border-b-2 border-blue-600 rounded-t-lg active':''"
                aria-current="page">Customers</a>
        </li>
    </ul>
</div>
<div class="flex flex-col mt-2">
    <div class="overflow-x-auto shadow-md sm:rounded-lg">
        <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden ">
                <table class="min-w-full divide-y divide-gray-200 table-fixed ">
                    <thead class="bg-gray-100 ">
                        <tr>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Video Title</th>

                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase float-right">
                            </th>
                        </tr>
                    </thead>
                    <div cdkDropList class="example-list w-full" (cdkDropListDropped)="drop($event)">
                        <tbody class="example-boundary  border-b-2 border-gray-300" *ngFor="let video of videoList" cdkDrag [cdkDragData]="video">
                            <tr class="example-box hover:bg-gray-100 cursor-pointer"
                                cdkDragBoundary=".example-boundary">
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 break-words w-full">
                                    {{video?.title}}</td>
                                <td
                                    class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 float-right">
                                    <div class="flex text-white w-full">
                                        <button class="p-3 bg-emerald-400  hover:bg-emerald-500 rounded m-1 relative"
                                            routerLink="../tech-comment" [queryParams]="{vid:video?.id}">
                                            <span class="mb-5">View Comments
                                            </span>
                                            <div *ngIf="video?.commentCount>0"
                                                class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                                                {{video?.commentCount}}</div>
                                        </button>
                                        <button class="p-1 bg-blue-400  hover:bg-blue-500 rounded m-1"
                                            (click)="openModal(video?.id,template)">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button class="p-1 bg-red-400  hover:bg-red-500 rounded m-1"
                                            (click)="deleteVideo(video?.id)" *ngIf="getPermisson('delete')">
                                            <mat-icon>delete_outline</mat-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </div>
                    <ng-template #noData>
                        <tbody>
                            <tr>
                                <td colspan="12" class="text-center" style="font-size: 14px">
                                    No data available
                                </td>
                            </tr>
                        </tbody>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>
<mat-paginator [length]="videoList?.totalPage" [pageSize]="20" hidePageSize="true" (page)="onPaginateChange($event)">
</mat-paginator>
<ng-template #template>
    <div class="modal-header flex justify-end p-0 m-0 ">
        <div>
            <button (click)="closeModal()" class="float-right"><mat-icon>close</mat-icon></button>
        </div>
    </div>
    <div class="modal-content">
        <div class="p-5">
            <form [formGroup]="videoData" (submit)="onSubmit()" novalidate>
                <div class="grid grid-cols-1 items-center gap-3 w-full">

                    <div class="relative z-0 mb-6 w-full">
                        <input type="text" name="title"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " formControlName="title" />
                        <label for="title"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Title
                        </label>
                    </div>

                    <div class="relative z-0 mb-6 w-full">
                        <input type="text" name="videoLength"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " formControlName="videoLength" />
                        <label for="videoLength"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Video
                            Length
                        </label>
                    </div>

                    <div class="relative z-0 mb-6 w-full">
                        <label for="formFile" class="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
                            Thumbnail</label>
                        <input
                            class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                            type="file" id="formFile" (change)="fileupload($event,'image')" />
                    </div>
                    <div class="relative z-0 mb-6 w-full">
                        <label for="formFile" class="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
                            Upload Video</label>
                        <input
                            class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                            type="file" id="formFile" (change)="fileupload($event,'video') " />
                    </div>
                    <div class="relative z-0 mb-6 w-full">
                        <input type="text" name="description"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " formControlName="description" />
                        <label for="description"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Description
                        </label>
                    </div>
                </div>

                <div class="float-right p-3">
                    <button type="submit" data-te-ripple-init data-te-ripple-color="light" *ngIf="getPermisson('write')"
                        class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>