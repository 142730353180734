import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AdminService } from 'src/app/shared/services/admin-api/admin-api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { NotifierService } from 'src/app/shared/services/toaster.service';
import { Buffer } from 'buffer'
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NgbdSortableHeader } from 'src/app/shared/directives/sortable';

@Component({
  selector: 'app-add-langskill',
  templateUrl: './add-langskill.component.html',
  styleUrls: ['./add-langskill.component.css']
})
export class AddLangskillComponent {
  lsdata: FormGroup;
  astroId: any;
  dataList: any;
  fileViewer: any = 'No file Selected';
  permission: any;
  show = false;
  modalRef?: BsModalRef;
  prof: any;
  page = 1;
  type = "lang";
  tabOption = 1;
  constructor(
    private _FormBuilder: FormBuilder,
    private _AdminService: AdminService,
    private _SpinnerService: SpinnerService,
    private _NotifierService: NotifierService,
    private modalService: BsModalService,
    private _ActivatedRoute: ActivatedRoute,
    private _CommonService: CommonService
  ) {
    this.lsdata = this._FormBuilder.group({
      id: [null],
      Name: [null],
      Status: [1],
      type: this.type
    });
  }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe((params) => {
      this.astroId = params['astroid'];
      this.prof = params['prof'];
      if (params['permit'])
        this.permission = Buffer.from(params['permit'], 'base64').toString(
          'ascii'
        );
    });
    this.langskillList();
  }
  langskillList(id: any = null) {
    if (id) {
      this.lsdata.get('type')?.setValue(this.type);
    }
    this._SpinnerService.setState(true);
    this._AdminService
      .getlangskillList({ type: this.type, id: id })
      .then((data) => {
        if (id) {
          this.lsdata.patchValue(data?.data[0])
        }
        else if (data?.status) {
          this.dataList = data?.data;
        }
        this._SpinnerService.setState(false);
      });
  }

  deleteData(id = null) {
    if (confirm("Sure want to delete? ")) {
      this.lsdata.get('Status')?.setValue(0);
      this.lsdata.get('id')?.setValue(id);
      this.lsdata.get('type')?.setValue(this.type);
      this._AdminService.addlangskill(this.lsdata?.value).then((data) => {
        if (data?.status) {
          this.langskillList();
        }
      });
    }
  }
  onSubmit() {
    this._SpinnerService.setState(true);
    this._AdminService.addlangskill(this.lsdata?.value).then((data) => {
      if (data?.status) {
        this.langskillList();
        this.closeModal();
      }
      this._SpinnerService.setState(false);
    });
  }
  getPermisson(p_type: any) {
    return this._CommonService.getPermission(this.permission, p_type);
  }
  closeModal() {
    this.show = false;
    this.lsdata.reset();
    this.modalRef?.hide();
    this.langskillList();
  }
  openModal(dataId = null, template: TemplateRef<any>) {
    console.log(dataId)
    if (dataId)
      this.langskillList(dataId);
    this.modalRef = this.modalService.show(template, {
      class: 'modal-sm',
      backdrop: 'static',
      keyboard: false,
    });
  }

  setTabOption(id, type) {
    this.lsdata.get('type')?.setValue(type);
    this.type = type
    this.tabOption = id;
    this.langskillList();
  }

}
