<div class="p-5">
    <form [formGroup]="walletForm" *ngIf="!isOTP">
        <div class="col-span-12 relative z-0 mb-6 w-full">
            <input type="text" name="name"
                class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" " formControlName="remarks" />
            <label for="name"
                class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                Enter Remarks
            </label>
        </div>
        <div class="col-span-12 relative z-0 mb-6 w-full">
            <input type="number" name="name"
                class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" " formControlName="amount" />
            <label for="name"
                class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                Enter Amount
            </label>
        </div>
        <div class="col-span-12 relative z-0 mb-6 w-full">
            <input type="text" name="name"
                class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" " formControlName="paymentId" />
            <label for="name"
                class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                Payment Id (optional)
            </label>
        </div>
        <div class="flex items-center mb-4">
            <input id="default-radio-1" type="radio" value="1" name="default-radio" formControlName="type"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="default-radio-1" class="ml-2 text-sm font-medium text-green-500 dark:text-gray-300">Add (+)
                amount
                to
                the
                user wallet</label>
        </div>
        <div class="flex items-center">
            <input checked id="default-radio-2" type="radio" value="2" name="default-radio" formControlName="type"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="default-radio-2" class="ml-2 text-sm font-medium text-red-400 dark:text-gray-300">Add (-) amount
                to
                the
                user wallet</label>
        </div>

        <button (click)="submitMobile()"
            class="inline-flex items-center mt-2 px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Submit
        </button>
    </form>
    <form *ngIf="isOTP">
        <span class="py-5 text-lg">
            Enter OTP recieved:
        </span>
        <ng-otp-input *ngIf="isOTP" (onInputChange)="onOtpChange($event)" [config]="{ length: 6 }"></ng-otp-input>
        <div class="mt-4">
            <button (click)="recharge()"
                class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Submit
            </button>
        </div>
    </form>
</div>