import { Component } from '@angular/core';

@Component({
  selector: 'app-scratch-cards',
  templateUrl: './scratch-cards.component.html',
  styleUrls: ['./scratch-cards.component.css']
})
export class ScratchCardsComponent {

}
