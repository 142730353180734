<div class="flex justify-between mb-5">
    <h1>
        Manage Matchmaking Content:
    </h1>

</div>

<div class="flex flex-col">
    <div class="overflow-x-auto shadow-md sm:rounded-lg">
        <editor apiKey="begnna8r9a7fbs9rvbqbo6q8klj3f5b9a53d5vpkzw7v1ngq" [init]="config" [(ngModel)]="content">
        </editor>
    </div>
    <div class="my-5 p">
        <button type="submit" (click)="updateContent()"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>
    </div>
</div>