<!-- <div class="flex justify-between items-center mb-5"> -->
  <div class="mx-auto">

    <form [formGroup]="getProductData">
    <div class="flex justify-center mt-5 w-full">
        <div class="grid grid-cols-4 items-center gap-3 w-full">
          <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6"><h1>Manage Remedy:</h1></div>
          <div class="md:col-span-1  col-span-12 ">
                <ng-select class="" [items]="type"  placeholder="type" bindLabel="name" bindValue="value"
                     class="w-full" 
                     formControlName="filter"
                    (change)="getRemedyList()">
                </ng-select>
            </div>   
          <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
            <input type="text" class=" border-gray-200 w-full py-1 px-2 border-2 rounded-lg" placeholder="Search ..."
            (input)="searchProduct($event)">
          </div>
          <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
            <div class="w-full  ">
              <button
              (click)="openModal(template)"
                class="float-right inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Add Remedy
              </button>
            </div>
          </div>
        </div>
    </div>
</form>
    <div class="flex flex-col">
      <div class="overflow-x-auto shadow-md sm:rounded-lg">
        <div class="inline-block min-w-full align-middle">
          <div class="overflow-hidden">
            <table class="min-w-full divide-y divide-gray-200 table-fixed">
              <thead class="bg-gray-100">
                <tr>
                    <!-- <th
                    scope="col"
                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                  >
                    Thumbnail Image
                  </th> -->
                  <th
                    scope="col"
                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                  >
                    Remedy Name
                  </th>
                  <th
                      scope="col"
                      class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                    >
                      Astrologer Name
                    </th>
                  <th
                    scope="col"
                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                    >
                    Image
                    </th>
                  <th
                    scope="col"
                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody
                *ngIf="remedyList?.length !== 0; else noData"
                class="bg-white divide-y divide-gray-200"
              >
                <tr
                  *ngFor="let products of remedyList"
                  class="hover:bg-gray-100 cursor-pointer"
                >
                  <!-- <td class="text-sm font-medium text-gray-500 break-words">
                    <img
                      [src]="products?.image"
                      [alt]="products?.image"
                      class="w-[100px] h-[100px]"
                    />
                  </td> -->
                  <td
                    class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
                  >
                    {{ products?.remedy_name }}
                  </td>
                  <td
                      class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
                    >
                      {{ products?.fullname }}
                    </td>
                  <td
                    class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
                  >
                    {{ products?.type=="1" ? "video" : products?.type=="0"  ? "Image" : "Text" }}
                  </td>
                  <td class="text-sm font-medium text-gray-500 break-words">
                    <img *ngIf="products?.type=='0'"
                      [src]="products?.image"
                      [alt]="products?.image"
                      class="w-[100px] h-[100px]"
                    />

                    <a [href]="products?.video" target="_blank"
                        *ngIf="products?.type=='1'" 
                        class="px-3 py-2 rounded-lg bg-blue-700 text-white">
                        View viedo
                    </a>

                    <div *ngIf="products?.type=='2'">
                        <button 
                            (click)="openDetailsModal(template1);showDescription(products?.text)"
                            class="px-3 py-2 rounded-lg bg-blue-700 text-white"
                        >
                            See Description 
                        </button>
                    </div>
                  </td>
                  <td class="">
                    <button 
                    (click)="getRemedyId(products?.id)"
                        class="mr-2 px-3 py-2  rounded-lg bg-blue-400 text-white"
                    >
                    <mat-icon>edit</mat-icon>
                    </button>
                    <button
                    type="submit"
                    (click)="deleteRemedy(products?.id)"
                    class="px-3 py-2 rounded-lg bg-red-400 text-white"
                    >
                  <mat-icon>delete</mat-icon>
                  </button>
                  </td>
                </tr>
              </tbody>
              <ng-template #noData>
                <tbody>
                  <tr>
                    <td colspan="12" class="text-center" style="font-size: 14px">
                      No data available
                    </td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
          </div>
        </div>
      </div>
    </div>

</div>
    <mat-paginator
      [length]="paginationData?.totalPage"
      [pageSize]="1"
      hidePageSize="true"
      (page)="pagination($event)"
    >
    </mat-paginator>

    
    <!-- <mat-paginator [length]="blogList?.totalBlogs" [pageSize]="1" hidePageSize="true" (page)="pagination($event)">
    </mat-paginator> -->
    
    <ng-template #template>
      <div class="modal-header flex justify-between p-0 m-0">
        <div>
          <p class="font-bold text-lg">Add Remedy</p>
        </div>
        <div class="float-right">
          <button (click)="closeModal()"><mat-icon>close</mat-icon></button>
        </div>
      </div>
      <div class="modal-content">
        <div class="p-5">
            <form [formGroup]="addProduct" (submit)="isEdit ? updateProduct() : submitProduct()">
                <div class="flex justify-center mt-5 w-full">
                    <div class="grid grid-cols-2 items-center gap-3 w-full">
                      <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                        <input
                          type="text"
                          name="remedy_name"
                          class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          formControlName="remedy_name"
                        />
                        <label
                          for="remedy_name"
                          class="absolute  text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3  z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Remedy Name
                        </label>
                        <small
                          class="text-danger"
                          *ngIf="
                            addProduct.controls['remedy_name'].invalid &&
                            (isFormSubmitted ||
                              addProduct.controls['remedy_name'].touched)
                          "
                        >
                          Required</small
                        >
                      </div>
                      <div class="md:col-span-1  col-span-12 ">
                          <ng-select class="" [items]="type"  placeholder="type" bindLabel="name" bindValue="value"
                              formControlName="type" class="w-full" (change)="getType()">
                          </ng-select>
                          <small
                          class="text-danger"
                          *ngIf="
                            addProduct.controls['type'].invalid &&
                            (isFormSubmitted ||
                              addProduct.controls['type'].touched)
                          "
                        >
                          Required</small
                        >
                      </div>                      
                    </div>
                </div>
                <div class="flex justify-center mt-5 w-full">
                    <div class="grid grid-cols-1 items-center gap-3 w-full">
                        <ng-select
                        class=""
                        [multiple]="true"
                        [items]="astroList"
                        placeholder="Astrologers"
                        bindLabel="fullname"
                        bindValue="astroid"
                        class="w-full"
                        formControlName="selectedAstrologer"
                      >
                      <small
                      class="text-danger"
                      *ngIf="
                        addProduct.controls['selectedAstrologer'].invalid &&
                        (isFormSubmitted ||
                          addProduct.controls['selectedAstrologer'].touched)
                      "
                    >
                      Required</small
                    >
                      </ng-select>
                    </div>
                </div>
                <div class="flex justify-center mt-5 w-full">
                    <div class="grid grid-cols-1 items-center gap-3 w-full">
                        <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                            <textarea
                              type="text"
                              name="desc"
                              class="block h-32 py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              cols="3"
                              formControlName="desc"
                            ></textarea>
                            <label
                              for="desc"
                              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                            Remedy Description
                            </label>
                            <small
                            class="text-danger"
                            *ngIf="
                                addProduct.controls['desc'].invalid &&
                                (isFormSubmitted ||
                                addProduct.controls['desc'].touched)
                            "
                            >
                            Required</small
                            >
                          </div>
                    </div>
                </div>
                <div class="flex justify-center mt-5 w-full">
                    <div class="grid grid-cols-2 items-center gap-3 w-full">
                        <div class="md:col-span-1 col-span-12 relative z-0 mb-6 " *ngIf="imgSelected==true" >
                            <label for="formFile" class="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
                                Image (Size:400px X 400px)</label>
                            <input
                                name="image"
                                class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                                type="file" id="formFile" (change)="fileupload($event,true)" accept=".webp" />
                        </div>
                        <div class="md:col-span-1 col-span-12 relative z-0 mb-6 w-full" *ngIf="videoSelected==true">
                            <!-- <label for="formFile" class="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
                                Image (Size:400px X 400px)</label>
                            <input
                                class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                                type="file" id="formFile" (change)="fileupload($event,4)" accept=".webp" /> -->
                                <label
                                for="formFile"
                                class="mb-2 inline-block text-neutral-700 dark:text-neutral-200"
                              >
                                Remedy Video (Size:1400px X 800px)</label
                              >
                              <input
                                class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                                type="file"
                                id="formFile"
                                (change)="fileupload($event,false)"
                              />
                        </div>
                    </div>
                </div>
                <div class="flex justify-end w-full">
                    <button
                      type="submit"
                      class="m-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      {{isEdit?'Update':'Submit'}}
                    </button>
                </div>
            </form>
        </div>
      </div>
    </ng-template>

    <ng-template #template1>
        <div class="z-40 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="exampleModalScrollable" tabindex="-1" aria-labelledby="exampleModalScrollableLabel" aria-hidden="true">
        <div class="sm:h-[calc(100%-3rem)] max-w-lg my-6 mx-auto relative w-auto pointer-events-none">
          <div
            class="max-h-full overflow-hidden border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <!-- <div
              class="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
                Modal title
              </h5>
              <button type="button"
                class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal" aria-label="Close"></button>
            </div> -->
            <div class="modal-header flex justify-between p-0 m-0">
                <div>
                  <p class="font-bold text-xl">Description</p>
                </div>
                <div class="float-right">
                  <button (click)="closeModal()"><mat-icon>close</mat-icon></button>
                </div>
              </div>
            <div class="flex-auto overflow-y-auto relative p-4">
                {{descriptionVal}}
            </div>
            <div
              class="flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <button type="button"
                class="inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                data-bs-dismiss="modal" (click)="closeModal()">
                Close
              </button>
              <!-- <button type="button"
                class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                Save changes
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    