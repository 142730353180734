<h1 class="mb-5">Dakshina Report:</h1>
<div class="flex justify-between">
    <div>
        <form class="container border-2 border-slate-300  py-1 rounded ">
            <input [(ngModel)]="modelDate" class="border-2 border-slate-300 py-1 px-2 w-full bg-red-300"
                autocomplete="off" class="form-control" name="date" bsDatepicker
                [bsConfig]="{dateInputFormat: 'MMMM/YYYY'}" (onShown)="onOpenCalendar($event)"
                placeholder="Select Month and Year" (bsValueChange)="getDate($event)">
        </form>
    </div>
    <div>
        <input type="text" class="w-full border-2 border-slate-300 px-2 py-1 rounded" placeholder="Search..."
            (input)="searchTxt($event)">
    </div>

</div>

<div class="flex flex-col mt-5">
    <div class="overflow-x-auto shadow-md sm:rounded-lg">
        <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden">
                <table class="min-w-full divide-y divide-gray-200 table-fixed text-center">
                    <thead class="bg-gray-100">
                        <tr>

                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider  text-gray-700 uppercase">
                                Astrologer Name
                            </th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider  text-gray-700 uppercase">
                                Astro Amt
                            </th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider  text-gray-700 uppercase">
                                Comp Amt
                            </th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider  text-gray-700 uppercase">
                                Total Dakhisna Recieved
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of dakshinaList">
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                {{data?.astroName}}
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                &#8377; {{data?.astroAmt}}
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                &#8377; {{data?.compAmt}}
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                &#8377; {{parseFloat(data?.astroAmt)+parseFloat(data?.compAmt)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<mat-paginator [length]="totalPage" [pageSize]="1" hidePageSize="true" (page)="pagination($event)">
</mat-paginator>

<div class="flex justify-center w-full mt-20">
    <div class="grid grid-cols-8 items-center gap-1 w-full text-xs">
        <div class="md:col-span-1 col-span-12">
            <div>Total Dakshina Amount</div>
            <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
                &#8377; {{parseFloat(totalData?.compAmt)+parseFloat(totalData?.astroAmt)}}
            </div>
        </div>
        <div class="md:col-span-1 col-span-12">
            <div>Total Astro Amount</div>
            <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
                &#8377; {{totalData?.astroAmt}}
            </div>
        </div>
        <div class="md:col-span-1 col-span-12">
            <div>Total Comp Share</div>
            <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
                &#8377; {{totalData?.compAmt}}
            </div>
        </div>


        <div class="md:col-span-1 col-span-12 mt-4">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-2 rounded" (click)="exportexcel()">
                Export to Excel
            </button>
        </div>
    </div>
</div>