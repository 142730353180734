<!-- app.component.html -->

<div class="container mx-auto p-4">
    <h2 class="text-3xl font-semibold mb-8 text-center">Profit/Loss Table</h2>
    <form [formGroup]="profitLossData">
        <div class="grid grid-cols-2 items-center gap-3 w-1/3">
            <div class="md:col-1 col-span-12"></div>

            <div class="md:col-1 col-span-12">
                <input type="text" class="border-2 py-1 px-2 w-full" ngxDaterangepickerMd [showCustomRangeLabel]="true"
                    [(ngModel)]="selected" [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true"
                    [isInvalidDate]="isInvalidDate" formControlName="StartDate" [showClearButton]="true"
                    placeholder="Select date range " (change)="choosedDate($event)" />
            </div>
            <!-- <div class="md:col-1 col-span-12"></div>
            <div class="md:col-1 col-span-12"></div> -->
    
        </div>
    </form>

    <table class="min-w-full bg-white border border-gray-300 shadow-md rounded-lg overflow-hidden">
        <thead>
            <tr class="bg-slate-600 text-white">
                <th class="py-3 px-4 border-b">Month</th>
                <th class="py-3 px-4 border-b">Total Orders</th>
                <th class="py-3 px-4 border-b">Orders @ Free</th>
                <th class="py-3 px-4 border-b">Orders Free Slot @ {{astroplData?.free_slot_price||0}} /-</th>
                <th class="py-3 px-4 border-b">Orders Repeat Promo @ 5 /-</th>
                <th class="py-3 px-4 border-b">Orders @ Paid</th>
                <th class="py-3 px-4 border-b">Gifts</th>
                <th class="py-3 px-4 border-b">AstroShop</th>
                <th class="py-3 px-4 border-b">Puja</th>
            </tr>
        </thead>
        <!-- <tbody *ngFor="let astroData of astroplData?.report">
            <tr class="hover:bg-gray-100 transition-all col-span-1">
                <td class="py-2 px-4 border-b text-center">March</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
            </tr> 
             <tr class="hover:bg-gray-100 transition-all col-span-1">
                <td class="py-2 px-4 border-b text-center">Paid to Astrologer</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
            </tr>
        </tbody> -->
        <tbody>
            <tr class="hover:bg-gray-100 transition-all">
                <td class="py-2 px-4 border-b text-center"><p class="text-green-600">{{astroplData?.from_date}}</p> to <p class="text-red-600">{{astroplData?.to_date}}</p></td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.total_no_of_orders ? astroplData?.total_no_of_orders : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.free_orders ? astroplData?.free_orders : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.nine_rs_orders ? astroplData?.nine_rs_orders : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders ? astroplData?.five_rs_orders : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.paid_orders ? astroplData?.paid_orders : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.total_gifts ? astroplData?.total_gifts : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.astroshop_count ? astroplData?.astroshop_count : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.puja_count ? astroplData?.puja_count : 0}}</td>
            </tr>
            <tr class="hover:bg-gray-100 transition-all col-span-1">
                <td class="py-2 px-4 border-b text-center">Paid to Astrologer</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.astro_share_total ? astroplData?.astro_share_total : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.astro_share_Free_total ? astroplData?.astro_share_Free_total : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.astro_share_nine_total ? astroplData?.astro_share_nine_total : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.astro_share_five_total ? astroplData?.astro_share_five_total: 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.astro_share_paid_total ? astroplData?.astro_share_paid_total : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.gift_astro_total ? astroplData?.gift_astro_total : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.astroshop_ast_total ? astroplData?.astroshop_ast_total : 0}}</td>
            </tr>

            <tr class="hover:bg-gray-100 transition-all col-span-1">
                <td class="py-2 px-4 border-b text-center">Company Share</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.comp_share_total ? astroplData?.comp_share_total : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.comp_share_Free_total ? astroplData?.comp_share_Free_total : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.comp_share_nine_total ? astroplData?.comp_share_nine_total : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.comp_share_five_total ? astroplData?.comp_share_five_total : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.comp_share_paid_total ? astroplData?.comp_share_paid_total : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.gift_comp_total ? astroplData?.gift_comp_total : 0}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.astroshop_comp_total ? astroplData?.astroshop_comp_total : 0}}</td>
            </tr>
            <tr class="hover:bg-gray-100 transition-all col-span-1">
                <td class="py-2 px-4 border-b text-center">Company Earning</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.company_earning ? astroplData?.company_earning : 0}}</td>
                <!-- <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td> -->
                
            </tr>
            <tr class="hover:bg-gray-100 transition-all col-span-1"
                
            >
                <td class="py-2 px-4 border-b text-center">Company Profit/Loss</td>
                <td class="py-2 px-4 border-b text-center font-bold"
                [ngClass]="astroplData?.profiltOrLoss=='Profit' ? 'text-green-600' : 'text-red-600' "
                >{{astroplData?.profiltOrLoss ? astroplData?.profiltOrLoss : "N/A"}}</td>
                <!-- <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td>
                <td class="py-2 px-4 border-b text-center">{{astroplData?.five_rs_orders}}</td> -->
            </tr>
        </tbody>
    </table>
</div>