<div *ngIf="TabOption==0">
  <div class="flex flex-wrap gap-x-4 gap-y-12 bg-gray-100 px-4 py-20 lg:px-20 ">
    <div
      class="border-slate-400 bg-white m-0 flex max-w-xs flex-col items-center rounded-xl border px-4 py-4 text-center md:max-w-lg md:flex-row md:items-start md:text-left">
      <div class="mb-4 md:mr-6 md:mb-0">
        <img class=" rounded-lg object-cover md:w-auto h-auto w-auto" src="{{profileImage}}" alt="" />
      </div>
      <div class="">
        <div class="flex justify-between">
          <p class="text-xl font-medium text-gray-700">{{customerName}}</p>
          <p class="text-xs font-bold text-blue-700">Current version: {{appVersion}}</p>
        </div>
        <p class="text-sm font-medium text-gray-700">{{customerMoblieNo}}</p>
        <p *ngIf="!deleted_at" class="mb-4 text-sm font-medium text-gray-500 animate-pulse "
          [ngClass]="accountStatus == '1' ? 'text-green-600' : 'text-red-600'">{{accountStatus == '1' ? "Active" :
          "Blocked" }}</p>
        <p *ngIf="deleted_at" class="mb-4 text-sm font-medium text-gray-500">
          Deleted @ {{deleted_at | date:"medium"}}</p>
        <div class="flex space-x-2">
          <div class="flex flex-col items-center rounded-xl bg-gray-100 px-4 py-2">
            <p class="text-sm font-medium text-gray-500">Calls</p>
            <p class="text-3xl font-medium text-gray-600">{{totalCallCount}}</p>
          </div>
          <div class="flex flex-col items-center rounded-xl bg-gray-100 px-4 py-2">
            <p class="text-sm font-medium text-gray-500">Chats</p>
            <p class="text-3xl font-medium text-gray-600">{{totalChatCount}}</p>
          </div>
          <div class="flex flex-col items-center rounded-xl bg-gray-100 px-4 py-2">
            <p class="text-sm font-medium text-gray-500">VideoCalls</p>
            <p class="text-3xl font-medium text-gray-600">{{totalVideoCallCount}}</p>
          </div>
          <div class=""></div>
        </div>
        <div class="mb-3"></div>
        <div class="flex space-x-2" *ngIf="!isAdmin()">
          <button class="w-full rounded-lg border-2  px-4 py-2 font-medium text-white" [ngClass]="accountStatus=='0'?'bg-green-600' 
              :  'bg-red-600'
              " (click)="blockUser()">{{ this.accountStatus == "0" ? "Unblock" : "Block"}}</button>
          <button class="w-full rounded-lg border-2 border-transparent bg-blue-600 px-4 py-2 font-medium text-white"
            (click)="setTabOption(1)">Edit Profile</button>
        </div>
        <div class="flex space-x-2" *ngIf="isAdmin()">
          <button *ngIf="!connetCall" class="w-full rounded-lg border-2  px-4 py-3 font-medium text-white bg-green-600"
            ngClass="" (click)="startCall()">Call Now<mat-icon class="text-sm pt-2">call</mat-icon></button>
          <button *ngIf="connetCall" class="w-full rounded-lg border-2  px-4 py-3 font-medium text-white"
            ngClass="bg-red-600" (click)="disconnectCall()">Disconnet Call<mat-icon
              class="text-sm pt-2">call</mat-icon></button>
        </div>
      </div>
    </div>

    <div class="flex w-56">
      <div
        class="flex w-full max-w-full flex-col break-words rounded-lg border border-gray-100 bg-white text-gray-600 shadow-lg">
        <div class="p-3">
          <div
            class="absolute -mt-10 h-16 w-16 rounded-xl bg-gradient-to-tr from-gray-700 to-gray-400 text-center text-white shadow-lg">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 h-7 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg> -->
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" class="mt-4 h-7 w-16" fill="none"
              stroke="currentColor" stroke-width="2" width="50.000000pt" height="50.000000pt"
              viewBox="0 0 50.000000 50.000000" preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path d="M311 445 c-108 -51 -280 -252 -281 -329 0 -22 62 -86 84 -86 9 0 32
              25 53 55 36 55 36 56 20 89 l-15 33 60 61 60 61 34 -16 c33 -16 34 -16 89 20
              30 21 55 44 55 52 0 24 -60 85 -84 85 -11 0 -45 -12 -75 -25z m112 -23 c15
              -15 27 -31 27 -35 0 -3 -21 -20 -46 -38 -42 -29 -48 -30 -72 -18 -15 7 -30 15
              -34 17 -9 4 -126 -105 -139 -130 -6 -11 -4 -26 8 -46 16 -28 16 -30 -16 -75
              -17 -26 -35 -47 -40 -47 -4 0 -20 13 -35 29 -23 24 -27 35 -22 58 25 100 247
              313 327 313 8 0 27 -13 42 -28z" />
              </g>
            </svg>
          </div>
          <div class="pt-1 text-right">
            <p class="text-sm font-light capitalize">Calls</p>
            <h4 class="text-2xl font-semibold tracking-tighter xl:text-2xl">{{totalCallCount}}</h4>
          </div>
        </div>
        <hr class="opacity-50" />
        <div class="p-4">
          <p class="font-light"><span class="text-sm font-bold text-green-600">{{ callLogsStatus ?
              callLogsStatus.totalCompletedCall : 0}} </span>Completed</p>
          <p class="font-light"><span class="text-sm font-bold text-red-600">{{callLogsStatus ?
              callLogsStatus.totalMissedCall : 0}} </span>Missed</p>
          <p class="font-light"><span class="text-sm font-bold text-green-800">{{callLogsStatus ?
              callLogsStatus.totalInProgressCall : 0}} </span>InProgress</p>
          <p class="font-light"><span class="text-sm font-bold text-blue-600">{{callLogsStatus ?
              callLogsStatus.totalCancelledCall : 0}} </span>Cancelled</p>
          <p class="font-light"><span class="text-sm font-bold text-orange-600">{{callLogsStatus ?
              callLogsStatus.totalRefundedCall : 0}} </span>Refunded</p>
          <p class="font-light"><span class="text-sm font-bold text-yellow-600">{{callLogsStatus ?
              callLogsStatus.totalNotRespondedCall : 0}}</span>Not Responded</p>

        </div>
      </div>
    </div>
    <div class="flex w-56">
      <div
        class="flex w-full max-w-full flex-col break-words rounded-lg border border-gray-100 bg-white text-gray-600 shadow-lg">
        <div class="p-3">
          <div
            class="absolute -mt-10 h-16 w-16 rounded-xl bg-gradient-to-tr from-blue-300 to-blue-100 text-center text-white shadow-lg">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 h-7 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg> -->
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"
              class="mt-4 h-7 w-16" version="1.1" id="Layer_1" viewBox="0 0 512 512" xml:space="preserve">
              <g>
                <g>
                  <g>
                    <path
                      d="M458.667,85.333h-64C388.779,85.333,384,90.112,384,96s4.779,10.667,10.667,10.667h64c17.643,0,32,14.357,32,32v294.229     l-67.115-67.115c-2.005-1.984-4.715-3.115-7.552-3.115H202.667c-5.888,0-10.667,4.779-10.667,10.667     c0,5.888,4.779,10.667,10.667,10.667h208.917l82.219,82.219c2.027,2.027,4.757,3.115,7.531,3.115     c1.365,0,2.752-0.256,4.053-0.811c4.011-1.643,6.613-5.547,6.613-9.856v-320C512,109.269,488.064,85.333,458.667,85.333z" />
                    <path
                      d="M362.667,288V96c0-29.397-23.936-53.333-53.333-53.333h-256C23.936,42.667,0,66.603,0,96v320     c0,4.309,2.603,8.213,6.592,9.856c1.344,0.555,2.731,0.811,4.075,0.811c2.773,0,5.504-1.088,7.531-3.115l82.24-82.219h208.896     C338.731,341.333,362.667,317.397,362.667,288z M96,320.021L96,320.021c-2.837,0-5.547,1.109-7.552,3.115l-67.115,67.115V96     c0-17.643,14.379-32,32-32v0.021h256c17.643,0,32,14.357,32,32v192c0,17.643-14.357,32-32,32H96z" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="pt-1 text-right">
            <p class="text-sm font-light capitalize">Chats</p>
            <h4 class="text-2xl font-semibold tracking-tighter xl:text-2xl">{{totalChatCount}}</h4>
          </div>
        </div>
        <hr class="opacity-50" />
        <div class="p-4">
          <p class="font-light"><span class="text-sm font-bold text-green-600">{{callLogsStatus ?
              callLogsStatus.totalCompletedChat : 0}} </span>Completed</p>
          <p class="font-light"><span class="text-sm font-bold text-red-600">{{callLogsStatus ?
              callLogsStatus.totalMissedChat : 0}} </span>Missed</p>
          <p class="font-light"><span class="text-sm font-bold text-green-800">{{callLogsStatus ?
              callLogsStatus.totalInProgressChat : 0}} </span>InProgress</p>
          <p class="font-light"><span class="text-sm font-bold text-blue-600">{{callLogsStatus ?
              callLogsStatus.totalCancelledChat : 0}} </span>Cancelled</p>
          <p class="font-light"><span class="text-sm font-bold text-orange-600">{{callLogsStatus ?
              callLogsStatus.totalRefundedChat : 0}} </span>Refunded</p>
          <p class="font-light"><span class="text-sm font-bold text-yellow-600">{{callLogsStatus ?
              callLogsStatus.totalNotRespondedChat : 0}}</span>Not Responded</p>

        </div>
        <!-- <div class="p-4">
          <p class="font-light"><span class="text-sm font-bold text-green-600">+3% </span>vs last month</p>
        </div> -->
      </div>
    </div>
    <div class="flex w-56">
      <div
        class="flex w-full max-w-full flex-col break-words rounded-lg border border-gray-100 bg-white text-gray-600 shadow-lg">
        <div class="p-3">
          <div
            class="absolute -mt-10 h-16 w-16 rounded-xl bg-gradient-to-tr from-emerald-700 to-emerald-500 text-center text-white shadow-lg">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 h-7 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg> -->
            <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 h-7 w-16" viewBox="0 0 32 32" fill="none">
              <path
                d="M2 11.9556C2 8.47078 2 6.7284 2.67818 5.39739C3.27473 4.22661 4.22661 3.27473 5.39739 2.67818C6.7284 2 8.47078 2 11.9556 2H20.0444C23.5292 2 25.2716 2 26.6026 2.67818C27.7734 3.27473 28.7253 4.22661 29.3218 5.39739C30 6.7284 30 8.47078 30 11.9556V20.0444C30 23.5292 30 25.2716 29.3218 26.6026C28.7253 27.7734 27.7734 28.7253 26.6026 29.3218C25.2716 30 23.5292 30 20.0444 30H11.9556C8.47078 30 6.7284 30 5.39739 29.3218C4.22661 28.7253 3.27473 27.7734 2.67818 26.6026C2 25.2716 2 23.5292 2 20.0444V11.9556Z"
                fill="url(#paint0_linear_87_7281)" />
              <path
                d="M6.31141 11.512C6 12.1044 6 12.8799 6 14.4308V17.5692C6 19.1201 6 19.8956 6.31141 20.488C6.58533 21.009 7.02242 21.4327 7.56003 21.6982C8.1712 22 8.97128 22 10.5714 22H15.3968C16.997 22 17.7971 22 18.4082 21.6982C18.9458 21.4327 19.3829 21.009 19.6568 20.488C19.9683 19.8956 19.9683 19.1201 19.9683 17.5692V14.4308C19.9683 12.8799 19.9683 12.1044 19.6568 11.512C19.3829 10.991 18.9458 10.5673 18.4082 10.3018C17.7971 10 16.997 10 15.3968 10H10.5714C8.97128 10 8.1712 10 7.56003 10.3018C7.02242 10.5673 6.58533 10.991 6.31141 11.512Z"
                fill="white" />
              <path
                d="M21.0573 13.4003L24.1751 10.8672C24.8982 10.2797 26 10.778 26 11.6925V20.3334C26 21.2435 24.9074 21.7433 24.1825 21.1647L21.0658 18.6769C20.7729 18.4431 20.6032 18.0946 20.6032 17.7269V14.3435C20.6032 13.9795 20.7694 13.6341 21.0573 13.4003Z"
                fill="white" />
              <defs>
                <linearGradient id="paint0_linear_87_7281" x1="16" y1="2" x2="16" y2="30"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#61F677" />
                  <stop offset="1" stop-color="#10BC2B" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="pt-1 text-right">
            <p class="text-sm font-light capitalize">Video Calls</p>
            <h4 class="text-2xl font-semibold tracking-tighter xl:text-2xl">{{totalVideoCallCount ? totalVideoCallCount
              : 0}}</h4>
          </div>
        </div>
        <hr class="opacity-50" />
        <!-- <div class="p-4">
          <p class="font-light"><span class="text-sm font-bold text-red-600">-3% </span>vs last month</p>
        </div> -->
        <div class="p-4">
          <p class="font-light"><span class="text-sm font-bold text-green-600">{{callLogsStatus ?
              callLogsStatus.totalCompletedVcall : 0}} </span>Completed</p>
          <p class="font-light"><span class="text-sm font-bold text-red-600">{{callLogsStatus ?
              callLogsStatus.totalMissedVcall : 0}} </span>Missed</p>
          <p class="font-light"><span class="text-sm font-bold text-green-800">{{callLogsStatus ?
              callLogsStatus.totalInProgressVcall : 0}} </span>InProgress</p>
          <p class="font-light"><span class="text-sm font-bold text-blue-600">{{callLogsStatus ?
              callLogsStatus.totalCancelledVcall : 0}} </span>Cancelled</p>
          <p class="font-light"><span class="text-sm font-bold text-orange-600">{{callLogsStatus ?
              callLogsStatus.totalRefundedVcall : 0}} </span>Refunded</p>
          <p class="font-light"><span class="text-sm font-bold text-yellow-600">{{callLogsStatus ?
              callLogsStatus.totalNotRespondedVcall : 0}}</span>Not Responded</p>

        </div>
      </div>
    </div>
    <div class="flex w-56">
      <div
        class="flex w-full max-w-full flex-col break-words rounded-lg border border-gray-100 bg-white text-gray-600 shadow-lg">
        <div class="p-3">
          <div
            class="absolute -mt-10 h-16 w-16 rounded-xl bg-gradient-to-tr from-gray-700 to-gray-500 text-center text-white shadow-lg">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 h-7 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg> -->
            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 h-7 w-16" viewBox="0 0 32 32" fill="none">
              <path d="M2 11.9556C2 8.47078 2 6.7284 2.67818 5.39739C3.27473 4.22661 4.22661 3.27473 5.39739 2.67818C6.7284 2 8.47078 2 11.9556 2H20.0444C23.5292 2 25.2716 2 26.6026 2.67818C27.7734 3.27473 28.7253 4.22661 29.3218 5.39739C30 6.7284 30 8.47078 30 11.9556V20.0444C30 23.5292 30 25.2716 29.3218 26.6026C28.7253 27.7734 27.7734 28.7253 26.6026 29.3218C25.2716 30 23.5292 30 20.0444 30H11.9556C8.47078 30 6.7284 30 5.39739 29.3218C4.22661 28.7253 3.27473 27.7734 2.67818 26.6026C2 25.2716 2 23.5292 2 20.0444V11.9556Z" fill="url(#paint0_linear_87_7281)"/>
              <path d="M6.31141 11.512C6 12.1044 6 12.8799 6 14.4308V17.5692C6 19.1201 6 19.8956 6.31141 20.488C6.58533 21.009 7.02242 21.4327 7.56003 21.6982C8.1712 22 8.97128 22 10.5714 22H15.3968C16.997 22 17.7971 22 18.4082 21.6982C18.9458 21.4327 19.3829 21.009 19.6568 20.488C19.9683 19.8956 19.9683 19.1201 19.9683 17.5692V14.4308C19.9683 12.8799 19.9683 12.1044 19.6568 11.512C19.3829 10.991 18.9458 10.5673 18.4082 10.3018C17.7971 10 16.997 10 15.3968 10H10.5714C8.97128 10 8.1712 10 7.56003 10.3018C7.02242 10.5673 6.58533 10.991 6.31141 11.512Z" fill="white"/>
              <path d="M21.0573 13.4003L24.1751 10.8672C24.8982 10.2797 26 10.778 26 11.6925V20.3334C26 21.2435 24.9074 21.7433 24.1825 21.1647L21.0658 18.6769C20.7729 18.4431 20.6032 18.0946 20.6032 17.7269V14.3435C20.6032 13.9795 20.7694 13.6341 21.0573 13.4003Z" fill="white"/>
              <defs>
              <linearGradient id="paint0_linear_87_7281" x1="16" y1="2" x2="16" y2="30" gradientUnits="userSpaceOnUse">
              <stop stop-color="#61F677"/>
              <stop offset="1" stop-color="#10BC2B"/>
              </linearGradient>
              </defs>
              </svg> -->
            <p class="p-2 text-white font-bold text-sm">Promotional</p>
          </div>
          <div class="pt-1 text-right">
            <p class="text-sm font-light capitalize">Free/Promotional</p>
            <h4 class="text-2xl font-semibold tracking-tighter xl:text-2xl">{{totalFreePromoCount}}</h4>
          </div>
        </div>
        <hr class="opacity-50" />
        <!-- <div class="p-4">
          <p class="font-light"><span class="text-sm font-bold text-red-600">-3% </span>vs last month</p>
        </div> -->
        <div class="p-4">
          <p class="font-light"><span class="text-sm font-bold text-green-600">{{freePromotionalData ?
              freePromotionalData.promotionalCall : 0}} </span>Promotional Calls</p>
          <p class="font-light"><span class="text-sm font-bold text-red-600">{{freePromotionalData ?
              freePromotionalData.promotionalChat : 0}} </span>Promotional Chats</p>
          <p class="font-light"><span class="text-sm font-bold text-green-800">{{freePromotionalData ?
              freePromotionalData.freeCall : 0}} </span>Free Calls</p>
          <p class="font-light"><span class="text-sm font-bold text-green-800">{{freePromotionalData ?
              freePromotionalData.freeCall : 0}} </span>Free Chats</p>
          <p class="font-light"><span class="text-sm font-bold text-blue-600"> "N/A" </span>Free Video Calls</p>
        </div>
      </div>
    </div>
  </div>

  <div class="grid grid-cols-1 gap-x-4 gap-y-12 bg-gray-100 p-2 sm:grid-cols-2 sm:p-10 lg:grid-cols-3">


    <div class="max-w-md rounded-xl border bg-white p-6 pb-10 text-gray-900 tracking-wider">
      <p class="text-lg font-medium">Recent Transactions </p>
      <button class="text-xs text-blue-600" (click)="setLinkOption('txn')"> View all... </button>
      <div *ngIf="txnReportList?.length!==0">
        <div class="mt-4 flex items-center rounded-lg bg-gray-100 py-1 px-2 text-gray-600"
          *ngFor="let item of txnReportList">
          <svg *ngIf="item?.PaymentStatus=='Success'" xmlns="http://www.w3.org/2000/svg"
            class="mr-2 h-6 w-6 shrink-0 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <svg *ngIf="item?.PaymentStatus=='Declined'" xmlns="http://www.w3.org/2000/svg"
            class="mr-2 h-6 w-6 shrink-0 text-red-600" viewBox="0 0 24 24" width="50px" height="50px" fill="none"
            viewBox="0 0 50 50" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 32.990234 15.986328 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.990234 15.986328 z" />
          </svg>
          <div class="text-sm flex">Date: {{item?.TxnDate}}, Amount: <div class="font-bold">{{item?.TotalAmount |
              currency:'INR':'symbol'}}</div>
          </div>
        </div>
      </div>

      <!-- <div class="mt-4 flex items-center rounded-lg bg-gray-100 py-1 px-2 text-gray-600">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-6 w-6 shrink-0 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p class="text-sm">Your blog got featured on awwwards.com</p>
      </div>
      <div class="mt-4 flex items-center rounded-lg bg-gray-100 py-1 px-2 text-gray-600">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-6 w-6 shrink-0 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p class="text-sm">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Laboriosam deserunt cupiditate ipsa.</p>
      </div>
      <div class="mt-4 flex items-center rounded-lg bg-gray-100 py-1 px-2 text-gray-600">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-6 w-6 shrink-0 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p class="text-sm">Lorem ipsum dolor sit amet.</p>
      </div> -->
    </div>

    <div class="max-w-md rounded-xl border bg-white p-6 pb-10 text-gray-900">
      <p class="text-lg font-medium">Recent Call Logs</p>
      <button class="text-xs text-blue-600" (click)="setLinkOption('callLog')"> View all... </button>
      <div *ngIf="callLogsList?.length!==0">
        <div class="flex items-center py-2" *ngFor="let item of callLogsList">
          <img class="h-10 w-10 rounded-full object-cover" src="{{item?.astroData?.newImage}}" alt="Simon Lewis" />
          <p class="ml-4 w-56">
            <strong class="block font-medium">{{item?.astroData?.FullName}}</strong>
            <span class="text-xs text-gray-400">
              connected on
              <span class="font-extrabold">
                {{item?.orderType == "UCALL" ? "CALL" : item?.orderType == "UCHAT" ? "CHAT" : item?.orderType == "VCALL"
                ? "video-call" : null}}
              </span>
              <a class="truncate font-medium " href="#" [ngClass]="item?.orderStatus=='Completed'?'text-green-500'
                                  :item?.orderStatus=='Cancelled'?'text-red-500':
                                  item?.orderStatus=='Refunded'?'text-blue-500':'text-yellow-500'">
                {{item?.orderStatus}}
              </a> <br>
              <span class="truncate font-medium text-blue-600" *ngIf="item?.orderStatus=='Completed'"> duration
                {{item?.orderDuration}} </span>

            </span>
          </p>
        </div>
      </div>

      <!-- <div class="flex items-center py-2">
        <img class="h-10 w-10 rounded-full object-cover" src="/images/fR71TFZIDTv2jhvKsOMhC.png" alt="" />
        <p class="ml-4 w-56">
          <strong class="block font-medium">Samantha Ribbon</strong>
          <span class="text-xs text-gray-400"> Commented on <a class="truncate font-medium text-indigo-600" href="#">An Evening in the Woods</a> </span>
        </p>
      </div>
      <div class="flex items-center py-2">
        <img class="h-10 w-10 rounded-full object-cover" src="/images/R-Wx_NHvZBci3KLrgXhp1.png" alt="" />
        <p class="ml-4 w-56">
          <strong class="block font-medium">Dr. Kayla</strong>
          <span class="text-xs text-gray-400"> Commented on <a class="truncate font-medium text-indigo-600" href="#">An Evening in the Woods</a> </span>
        </p>
      </div> -->
    </div>

    <div class="max-w-md rounded-xl border bg-white p-6 pb-10 text-gray-900">
      <p class="text-lg font-medium">Recent Feedbacks</p>
      <button class="text-xs text-blue-600" (click)="setLinkOption('feedback')"> View all... </button>
      <div *ngIf="feedbackList?.length!==0">
        <div class="mt-4" *ngFor="let item of feedbackList">
          <p class="float-left mb-2">{{item?.message ? item?.message : "N/A"}}</p>
          <span class="float-right mb-2">{{item?.rating}}</span>
          <div class="h-1.5 w-full overflow-hidden rounded-full bg-gray-50">
            <div class="h-full w-{{item?.rating}}/5 overflow-hidden rounded-full bg-indigo-600"></div>
          </div>
        </div>
      </div>

      <!-- <div class="mt-4">
        <p class="float-left mb-2">Referral</p>
        <span class="float-right mb-2">2,000</span>
        <div class="h-1.5 w-full overflow-hidden rounded-full bg-gray-50">
          <div class="h-full w-4/12 overflow-hidden rounded-full bg-indigo-600"></div>
        </div>
      </div>
      <div class="mt-4">
        <p class="float-left mb-2">Google</p>
        <span class="float-right mb-2">1,500</span>
        <div class="h-1.5 w-full overflow-hidden rounded-full bg-gray-50">
          <div class="h-full w-3/12 overflow-hidden rounded-full bg-indigo-600"></div>
        </div>
      </div>
      <div class="mt-4">
        <p class="float-left mb-2">Facebook</p>
        <span class="float-right mb-2">260</span>
        <div class="h-1.5 w-full overflow-hidden rounded-full bg-gray-50">
          <div class="h-full w-1/12 overflow-hidden rounded-full bg-indigo-600"></div>
        </div>
      </div> -->
    </div>
  </div>
</div>


<div *ngIf="TabOption==1">
  <div class="">

    <form [formGroup]="customerData" (submit)="updateProfile()"
      class="relative border border-gray-100 space-y-3  mx-auto rounded-md bg-white p-6 shadow-xl lg:p-10">
      <!-- <h1 class="mb-6 text-xl font-semibold lg:text-2xl">Profile Detail</h1> -->
      <div class="flex flex-col border-b py-4 sm:flex-row sm:items-start">
        <div class="shrink-0 mr-auto sm:py-3">
          <!-- <p class="text-xl font-semibold lg:text-2xl">Account Details</p>
          <p class="text-sm text-gray-600">Edit your account details</p> -->

          <button
            class="hidden rounded-lg border-2 border-transparent bg-blue-600 px-4 py-2 font-medium text-white sm:inline focus:outline-none focus:ring hover:bg-blue-700"
            (click)="setTabOption(0)"> Back to profile</button>
        </div>
        <img class="w-24 h-24 mb-3 rounded-full shadow-lg" src="{{customerData.controls['image1'].value}}" />
        <!-- <button class="mr-2 hidden rounded-lg border-2 px-4 py-2 font-medium text-gray-500 sm:inline focus:outline-none focus:ring hover:bg-gray-200">Cancel</button>
        <button class="hidden rounded-lg border-2 border-transparent bg-blue-600 px-4 py-2 font-medium text-white sm:inline focus:outline-none focus:ring hover:bg-blue-700">Save</button> -->
      </div>

      <div class="grid gap-3 md:grid-cols-2">
        <div>
          <label class=""> First Name </label>
          <input type="text" formControlName="FirstName" placeholder="Your Name"
            class="mt-2 h-12 w-full rounded-md bg-gray-100 px-3" />
        </div>
        <div>
          <label class=""> Last Name </label>
          <input type="text" formControlName="LastName" placeholder="Last  Name"
            class="mt-2 h-12 w-full rounded-md bg-gray-100 px-3" />
        </div>
      </div>
      <div class="grid gap-3 md:grid-cols-2">
        <div>
          <label class=""> Mobile No: </label>
          <input type="text" formControlName="MobileNo" placeholder="+543 5445 0543"
            class="mt-2 h-12 w-full rounded-md bg-gray-100 px-3" />
        </div>
        <div>
          <label class=""> DOB</label>
          <input type="text" formControlName="DOB" placeholder="Last  Name"
            class="mt-2 h-12 w-full rounded-md bg-gray-100 px-3" />
        </div>
      </div>
      <div class="grid gap-3 md:grid-cols-2">
        <div>
          <label class=""> Birth Time </label>
          <input type="text" formControlName="BirthTime" placeholder="Your Name"
            class="mt-2 h-12 w-full rounded-md bg-gray-100 px-3" />
        </div>
        <div>
          <label class=""> Birth Place </label>
          <input type="text" formControlName="BirthPlace" placeholder="Last  Name"
            class="mt-2 h-12 w-full rounded-md bg-gray-100 px-3" />
        </div>
      </div>
      <div class="grid gap-3 md:grid-cols-2">
        <div>
          <label class=""> Gender </label>
          <div class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            <ng-select [items]="gender" bindValue="value" formControlName="Gender" bindLabel="name" class="select"
              name="gender" id="gender">
            </ng-select>
          </div>
        </div>
        <div>
          <label class=""> Marital Status </label>
          <input type="text" formControlName="maritial_status" placeholder="Last  Name"
            class="mt-2 h-12 w-full rounded-md bg-gray-100 px-3" />
        </div>
      </div>



      <!-- <div>
      <label class="">  </label>
      <input type="password" placeholder="******" class="mt-2 h-12 w-full rounded-md bg-gray-100 px-3" />
    </div> -->
      <div class="grid gap-3 lg:grid-cols-2">
        <!-- <div>
        <label class=""> Gender </label>
        <div class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            <ng-select [items]="country" bindValue="value" formControlName="CountryId" bindLabel="name"
                class="select" name="country" id="country">
            </ng-select>
        </div>
      </div> -->
        <!-- <div>
        <label class=""> Phone: <span class="text-sm text-gray-400">(optional)</span> </label>
        <input type="text" placeholder="+543 5445 0543" class="mt-2 h-12 w-full rounded-md bg-gray-100 px-3" />
      </div> -->
      </div>
      <div>
        <label class=""> Address </label>
        <textarea rows="4" cols="50" formControlName="Address" placeholder="Info@example.com"
          class="mt-2 w-full rounded-md bg-gray-100 px-3"></textarea>
      </div>
      <!-- <div class="checkbox">
      <input type="checkbox" id="chekcbox1" checked="" />
      <label for="checkbox1">I agree to the <a href="#" target="_blank" class="text-blue-600"> Terms and Conditions </a> </label>
    </div> -->

      <!-- <div class="flex h-56 w-full flex-col items-center justify-center gap-4 rounded-xl border border-dashed border-gray-300 p-5 text-center">
        <div class="flex flex-col items-center pb-10">
            <img class="w-24 h-24 mb-3 rounded-full shadow-lg" src="https://new-myastroguruji.s3.ap-south-1.amazonaws.com/astro-profile/1690432581374-Astro-Gyan-my-astroguruji.webp"  />
            <input type="file" class="max-w-full rounded-lg px-2 font-medium text-blue-600 outline-none ring-blue-600 focus:ring-1" />

        </div>

    </div> -->

      <div class="flex flex-col items-center justify-center">
        <button type="submit"
          class="mt-5 w-48  rounded-md bg-blue-600 p-2 text-center font-semibold text-white">Update</button>
      </div>

    </form>

  </div>
</div>