import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AdminService } from 'src/app/shared/services/admin-api/admin-api.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { NotifierService } from 'src/app/shared/services/toaster.service';
import option from '../../../../assets/constants/ngSelectvalues';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-astro-remedy',
  templateUrl: './astro-remedy.component.html',
  styleUrls: ['./astro-remedy.component.css']
})
export class AstroRemedyComponent {
  @ViewChild('template') template!: TemplateRef<any>;
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>; // Add ! to indicate it's definitely assigned
  modelChanged: Subject<any> = new Subject<any>();
  modalRef?: BsModalRef;
  remedyList: any;
  search: string = '';
  paginationData: any;
  addProduct: FormGroup;
  isFormSubmitted: boolean = false;
  type=option.remedyFilter
  isEdit:boolean=false
  productUpdateId:any=''
  videoSource2: string = 'https://new-myastroguruji.s3.ap-south-1.amazonaws.com/agoravideocall/recordings/012d85da2248cf6646c9a5a008a9ac42_videonVLd83V3gsMg1xovgdFCfT_20231114063224677.ts'
  videoSource: string = 'https://www.w3schools.com/html/mov_bbb.mp4';
  descriptionVal : string = ''
  initialValues:any
  filter: any 
  getProductData: FormGroup 
  astroList: any;
  imgSelected: boolean = false
  videoSelected: boolean = false
  selectedTypeVal: any
  // selectedAstrologer: any = [];


  constructor(
    private modalService: BsModalService,
    private _AdminService: AdminService,
    private _NotifierService: NotifierService,
    private _SpinnerService: SpinnerService,
    private _FormBuilder: FormBuilder
  ) {
    this.addProduct = this._FormBuilder.group({
      remedy_name: [null, [Validators.required]],
      type: [null, [Validators.required]],
      search: [''],
      filter: null,
      thumbnailImg: [''],
      video: [''],
      image: [''],
      desc:[''],
      selectedAstrologer: [null, [Validators.required]]
    });
    this.getProductData = this._FormBuilder.group({
      filter: null
    });
  }

  resetForm(){
    this.addProduct.reset(this.initialValues)
  }

  pagination($: any) {
    // this.page = $.pageIndex + 1;
    this.getRemedyList($.pageIndex + 1);
  }

  getType() {
    this.selectedTypeVal = this.addProduct.get('type')?.value
    if(this.selectedTypeVal == '0') {
      this.imgSelected = true;
      this.videoSelected = false;
    } else if (this.selectedTypeVal == '1') {
      this.imgSelected = false;
      this.videoSelected = true;
    }
  }

  getRemedyId(id:any){
    this._SpinnerService.setState(true)
    this._AdminService.getRemedyDetailById({id:id}).then(data=>{
      if(data?.status&& data?.data){
        this.productUpdateId=id
        console.log(data?.data, 'data .d.afadfa')
        this.addProduct.get('remedy_name')?.setValue(data?.data?.remedy_name)
        this.addProduct.get('type')?.setValue(data?.data?.type)
        this.addProduct.get('search')?.setValue(data?.data?.search)
        this.addProduct.get('video')?.setValue(data?.data?.video)
        this.addProduct.get('image')?.setValue(data?.data?.image)
        this.addProduct.get('selectedAstrologer')?.setValue(data?.data?.astroid)
        if ( data?.data?.text ) {
          this.addProduct.get('desc')?.setValue(Buffer?.from(
            data?.data?.text,
            'base64'
          ).toString())
        }


        this.openModal(this.template)
        this.isEdit=true
      }
      this._SpinnerService.setState(false)
    })
  }

  setFormData() {
    const formData = new FormData();
    formData.set('remedy_name', this.addProduct.get('remedy_name')?.value);
    formData.set('type', this.addProduct.get('type')?.value);
    formData.set('thumbnailImg', this.addProduct.get('thumbnailImg')?.value);
    formData.set('video', this.addProduct.get('video')?.value);
    formData.set('image', this.addProduct.get('image')?.value);
    formData.set('desc', this.addProduct.get('desc')?.value);
    formData.set('selectedAstrologer', this.addProduct.get('selectedAstrologer')?.value);
    if(this.isEdit){
      formData.set('id',this.productUpdateId);
    }

    return formData;
  }

  searchProduct(event: any) {
    this.modelChanged.next({ search: event.target.value })
  }

  playVideo() {
    this.videoElement.nativeElement.play();
  }

  pauseVideo() {
    this.videoElement.nativeElement.pause();
    this.videoElement.nativeElement.currentTime = 0;
  }

  ngOnInit(): void {
    this.initialValues = this.addProduct.value;
    this.modelChanged.pipe(debounceTime(300), distinctUntilChanged()).subscribe((val) => {
      this.search=val.search
      this.getRemedyList()
    });
    this.getRemedyList();
    this.getAstroList();
  }

  getAstroList() {
    this._AdminService.getAstroListForDropDown().then((data) => {
      if (data?.status) {
        this.astroList = data?.data;
      }
    });
  }

  deleteRemedy(id: any) {
    this._AdminService.deleteRemedy({ id: id}).then((data) => {
      if (data?.status) {
        this._NotifierService.showSuccess('Remedy Deleted Succesfully');
        this.getRemedyList()
      }
    });
  }


  getRemedyList(page: any = 1) {
    this._SpinnerService.setState(true);
    this._AdminService
      .getRemedyList({ ...this.getProductData.value,
        page: page, search: this.search
      })
      .then((data) => {
        if (data?.status) {
          this.remedyList = data?.data?.remedies;
          this.paginationData = data?.data;
        }
        this._SpinnerService.setState(false);
      });
  }

  showDescription(string: any) {
    this.descriptionVal = Buffer?.from(
      string,
      'base64'
    ).toString();
  }

  closeModal() {
    this.modalRef?.hide();
    this.isEdit=false
    this.productUpdateId=''
    this.addProduct.reset(this.initialValues)
    // this.selectedAstrologer = [];
  }

  openDetailsModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  
  openModal(template: TemplateRef<any>, blogId = null) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-xl',
      backdrop: 'static',
      keyboard: false,
    });
  }

  submitProduct(){
    // console.log(this.selectedAstrologer, 'selectedAstrologer....')
    this.isFormSubmitted=true
    if(this.addProduct?.valid){
      this._SpinnerService.setState(true)
      this._AdminService.addRemedy(this.setFormData()).then(data=>{
        if(data?.status){
          this._NotifierService.showSuccess('Data Added Successfully')
          this.modalRef?.hide()
          this.resetForm()
          this.getRemedyList()

        }else{
          this._NotifierService.showError('Something Went wrong')
        }
        this.isFormSubmitted=false
        this._SpinnerService.setState(false)

      })
    }
  }

  fileupload(event: Event, type: boolean = true): void {
    if (event.target !== null) {
      const files = (<HTMLInputElement>event.target).files;
      if (files !== null) {
        const fileList: FileList = files;
        if (fileList && fileList.length > 0) {
          const file: File = fileList[0];
          if (type) {
            this.addProduct.get('image')?.setValue(file);
          } else {
            this.addProduct.get('video')?.setValue(file);
          }
        }
      }
    }
  }

  updateProduct(){
    console.log(this.addProduct.value, 'form data...')
    console.log(this.productUpdateId, 'productUpdateId...')
    this.isFormSubmitted=true
    if(this.addProduct?.valid){
      this._SpinnerService.setState(true)
      this._AdminService.updateRemedy({...this.addProduct.value,id: this.productUpdateId }).then(data=>{
        if(data?.status){
          this._NotifierService.showSuccess('Data Updated Successfully')
          this.modalRef?.hide()
          this.isEdit=false
          this.productUpdateId=''
          this.resetForm()
          this.getRemedyList()
        }else{
          this._NotifierService.showError('Something Went wrong')
        }
        this.isFormSubmitted=false
        this._SpinnerService.setState(false)

      })
    }
  }
}
