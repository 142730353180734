import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/shared/services/admin-api/admin-api.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-astropl',
  templateUrl: './astropl.component.html',
  styleUrls: ['./astropl.component.css']
})
export class AstroplComponent {
  astroid: any;
  astroplData: any;
  profitLoss: any;
  startDate = new Date();
  selected: any = { start: this.startDate, end: this.startDate };
  profitLossData: FormGroup;
  ranges: any = {
    Today: [new Date(), new Date()],
    Yesterday: [
      moment().utc().subtract(1, 'days'),
      moment().subtract(1, 'days').local(),
    ],
    'Last 7 Days': [moment().utc().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().utc().subtract(29, 'days'), moment()],
    'This Month': [
      moment().utc().startOf('month'),
      moment().utc().endOf('month'),
    ],
    'Last Month': [
      moment().utc().subtract(1, 'month').startOf('month'),
      moment().utc().subtract(1, 'month').endOf('month'),
    ],
  };
  choosedDate(event: any) {
    this.profitLossData
      .get('from')
      ?.setValue(new Date(event?.startDate?.$d).valueOf() + new Date().getTimezoneOffset() * 1000 * 60);
    this.profitLossData
      .get('to')
      ?.setValue(new Date(event?.endDate?.$d).valueOf() + new Date().getTimezoneOffset() * 1000 * 60);
    this.fetchAstrodata();
  }
  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];
  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };
  constructor(
    private _AdminService: AdminService,
    private _FormBuilder: FormBuilder,
    private _SpinnerService: SpinnerService,
    private _ActivatedRoute: ActivatedRoute,
  ) { 
    this.profitLossData = this._FormBuilder.group({
      from: [this.startDate.setUTCHours(0, 0, 0, 0).valueOf() + new Date().getTimezoneOffset() * 1000 * 60],
      to: [this.startDate.setUTCHours(23, 59, 59, 999).valueOf() + new Date().getTimezoneOffset() * 1000 * 60],
      astroid: this.astroid 
    });
  }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe((params) => {
      this.astroid = params['astroid'];
    });
    this.profitLossData.get('astroid')?.setValue(this.astroid)
    this.fetchAstrodata();
  }
  fetchAstrodata() {
    this._SpinnerService.setState(true);
    this._AdminService.getAstroplreport(this.profitLossData.value).then((data) => {
      if (data?.status) {
        this.astroplData = data?.data;
        this.profitLoss = this.astroplData?.totalInfo?.compAmt - this.astroplData?.totalInfo?.astroAmt
      }
      this._SpinnerService.setState(false);

    })
  }
}
