import { Component, OnInit } from '@angular/core';
import option from "../../../../../assets/constants/ngSelectvalues"
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from 'src/app/shared/services/admin-api/admin-api.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { NotifierService } from 'src/app/shared/services/toaster.service';
import { ActivatedRoute } from '@angular/router';
import { Buffer } from 'buffer';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { CommonService } from 'src/app/shared/services/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-payment-logs',
  templateUrl: './payment-logs.component.html',
  styleUrls: ['./payment-logs.component.css']
})
export class PaymentLogsComponent {
  paymentLogList: any = []
  txnData: FormGroup
  ranges: any = {
    Today: [new Date(), new Date()],
    Yesterday: [
      moment().utc().subtract(1, 'days'),
      moment().subtract(1, 'days').local(),
    ],
    'Last 7 Days': [moment().utc().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().utc().subtract(29, 'days'), moment()],
    'This Month': [
      moment().utc().startOf('month'),
      moment().utc().endOf('month'),
    ],
    'Last Month': [
      moment().utc().subtract(1, 'month').startOf('month'),
      moment().utc().subtract(1, 'month').endOf('month'),
    ],
  };
  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];
  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };
  startDate = new Date();
  selected: any = { start: this.startDate, end: this.startDate };
  modalRef?: BsModalRef;
  modelChanged: Subject<any> = new Subject<any>();
  page: number = 1;
  option = option.payLogsStatus;
  filter = option.txnStatus;

  constructor(
    private _FormBuilder: FormBuilder,
    private _AdminService: AdminService,
    private _SpinnerService: SpinnerService,
    private _NotifierService: NotifierService,
    private _ActivatedRoute: ActivatedRoute,
    private _CommonService:CommonService
  ) {
    this.txnData = this._FormBuilder.group({
      CustomerId: null,
      from: [this.startDate.setUTCHours(0, 0, 0, 0).valueOf() + this.startDate.getTimezoneOffset() * 1000 * 60],
      to: [this.startDate.setUTCHours(23, 59, 59, 999).valueOf() + this.startDate.getTimezoneOffset() * 1000 * 60],
      status: [null],
      type: [null],
      page: this.page,
      search: [''],
      StartDate: [new Date(), Validators.required],
    })
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.txnData.get('CustomerId')?.setValue(params["id"])
    })
    this.modelChanged
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((val) => {
        this.txnData.get('search')?.setValue(val?.search);
        this.getTxnData();
      });
    this.getTxnData();
  }

  choosedDate(event: any) {
    this.txnData
      .get('from')
      ?.setValue(new Date(event?.startDate?.$d).valueOf() + new Date(event?.startDate?.$d).getTimezoneOffset() * 1000 * 60);
    this.txnData
      .get('to')
      ?.setValue(new Date(event?.endDate?.$d).valueOf() + new Date(event?.endDate?.$d).getTimezoneOffset() * 1000 * 60);
    this.getTxnData();
  }

  onPaginateChange($: any) {
    this.txnData.get('page')?.setValue($.pageIndex + 1);
    this.getTxnData();
  }

  search(event: any) {
    this.modelChanged.next({ search: event.target.value });
  }

  getTxnData() {
    this._SpinnerService.setState(true)
    this._AdminService.getPaymentLogs(this.txnData.value).then((data) => {
      if (data?.status) {
        this.paymentLogList = data?.data
      }
      this._SpinnerService.setState(false)
    })
  }
}
