<div class="">
    <p class="text-xl font-bold underline tracking-widest">Tarrot Cards</p>
</div>

<div class="mt-10">

    <button 
        class="bg-red-500 hover:bg-red-700 text-white text-right text-xs font-bold p-2 rounded"
        >
        Diable
    </button>

</div>