import { Component } from '@angular/core';

@Component({
  selector: 'app-on-board-astrologer',
  templateUrl: './on-board-astrologer.component.html',
  styleUrls: ['./on-board-astrologer.component.css']
})
export class OnBoardAstrologerComponent {

}
