<div class="flex justify-center w-full mt-10">
    <div class="grid grid-cols-8 items-center gap-1 w-full text-xs">
      <div class="md:col-span-1 col-span-12">
        <div>Total Completed Orders</div>
        <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
          {{ data?.completed_orders ?data?.completed_orders : 0}}
        </div>
      </div>
      <div class="md:col-span-1 col-span-12">
        <div>Total Missed Orders</div>
        <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
          {{ data?.total_missed_orders ? data?.total_missed_orders : 0 }}
        </div>
      </div>
      <div class="md:col-span-1 col-span-12">
        <div>Total Paid Completed Orders</div>
        <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
          {{ data?.total_completed_paid_orders ? data?.total_completed_paid_orders : 0 }}
        </div>
      </div>
      <div class="md:col-span-1 col-span-12">
        <div>Total Paid Missed Orders </div>
        <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
          {{ data?.total_missed_paid_orders ? data?.total_missed_paid_orders : 0 }}
        </div>
      </div>
      <div class="md:col-span-1 col-span-12">
        <div>Total Repeat User</div>
        <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
          {{ data?.repeat_user_count ? data?.repeat_user_count  : 0}}
        </div>
      </div>
      <!-- <div class="md:col-span-1 col-span-12">
        <div>Total TDSAmt</div>
        <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
          {{ 0}}
        </div>
      </div>
      <div class="md:col-span-1 col-span-12">
        <div>Total Net Amount</div>
        <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
          {{ 0 }}
        </div>
      </div> -->
    </div>
  </div>        
        
        
        
        <!-- Chart -->
        <div class="bg-white rounded-lg shadow-md p-6 my-6">
            <div class="w-52">
                <input [(ngModel)]="modelDate" class="border-2 border-slate-300 py-1 px-2 w-full mb-1"
                    autocomplete="off" name="date" bsDatepicker [bsConfig]="{dateInputFormat: 'MMMM/YYYY'}"
                    (onShown)="onOpenCalendar($event)" placeholder="Select Month and Year"
                    (bsValueChange)="getDate($event)" disabled>
            </div>

            


            <!-- Missed Paid orders -->
            <div class="w-full">
                <!-- <div class="mx-1"> -->
                    <!-- <img src="https://via.placeholder.com/500x300" alt="Chart" class="w-30"> -->
                    <div echarts [options]="missedOrdercharts" class="w-30 h-30 chart"></div>
                <!-- </div> -->
            </div>

            <!-- Completed Paid orders -->
            <div class="w-full">
                <div echarts [options]="completedPaidOrders" class="w-30 h-30 chart"></div>
            </div>

            <!-- Attended orders -->
            <div class="w-full">
                <div echarts [options]="attendedOrders" class="w-30 h-30 chart"></div>
            </div>

            <!-- Missed orders -->
            <div class="w-full">
                <div echarts [options]="missedOrders" class="w-30 h-30 chart"></div>
            </div>

            <!-- Busy Time orders -->
            <div class="w-full">
                <div echarts [options]="busyTime" class="w-30 h-30 chart"></div>
            </div>

            <!-- Paid Minutes orders -->
            <div class="w-full">
                <div echarts [options]="paidMinutesData" class="w-30 h-30 chart"></div>
            </div>

            <!-- Free Minutes orders -->
            <div class="w-full">
                <div echarts [options]="freeMinutesData" class="w-30 h-30 chart"></div>
            </div>

            <!-- Online Time Graph -->
            <div class="w-full">
              <div echarts [options]="onlineTime" class="w-30 h-30 chart"></div>
            </div>

            <!-- Repeat User Graph -->
            <div class="w-full">
              <div echarts [options]="repeatUserData" class="w-30 h-30 chart"></div>
            </div>
        </div>
