<div class="mt-3 mb-5 mr-5 ">
    <form [formGroup]="scratchCardData">
        <div class="rounded-xl border border-gray-200 bg-slate-100 p-4 shadow-l">
            
            <!-- <div class="flex">
                <div class="flex-1 flex items-end text-sm font-medium text-red-600">Customer Name: {{scratchCardDataList.customerData.FullName}} | MobileNo: {{scratchCardDataList.customerData.MobileNo}}</div>
                <div class="flex-1 text-left "><h2 class="text-stone-700 text-xl font-bold mt-3">Apply filters</h2></div>
            </div> -->

            <div class="flex">
                <div class="flex-1 flex items-end text-sm font-medium text-red-600">
                    <!-- Customer Name: {{feedbackList.customerData.FullName}} | MobileNo: {{feedbackList.customerData.MobileNo}} -->
                    <a class="block rounded-lg bg-gradient-to-r from-orange-500 to-rose-500 p-0.5 " href="#">
                        <div class="flex items-center rounded-md border border-gray-100 bg-white px-2 py-1 shadow-lg"> 
                          <img class="h-10 w-10 rounded-full object-cover" [src]="scratchCardDataList?.customerData?.customerImg" alt="cusotmer Image" />
                          <div class="ml-4 w-72">
                            <p class="text-sm font-semibold">{{scratchCardDataList?.customerData?.FullName }}</p>
                            <p class="text-sm text-gray-400 font-semibold">{{scratchCardDataList?.customerData?.MobileNo }} | <span class="text-green-600">Balance: ₹ {{scratchCardDataList?.customerData?.balance }}</span> /-</p> 
                          </div>
                        </div>
                      </a>
                      
                </div>
                <div class="flex-1 text-left "><h2 class="text-stone-700 text-xl font-bold mt-3">Apply filters</h2></div>
            </div>



                     <!-- <p class="mt-1 text-sm">Use filters to further refine search</p> -->
            <div class="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
                <div class="flex flex-col">
                <label for="search" class="text-stone-600 text-sm font-medium" >Search</label>
                <input type="text" id="name" (input)="search($event)" placeholder="search ..." class="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" />
                </div>
        
                <!-- <div class="flex flex-col">
                <label for="manufacturer" class="text-stone-600 text-sm font-medium">Manufacturer</label>
                <input type="manufacturer" id="manufacturer" placeholder="cadbery" class="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" />
                </div> -->
        
                <!-- <div class="flex flex-col">
                <label for="date" class="text-stone-600 text-sm font-medium">Date of Entry</label>
                <input type="date" id="date" class="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" />
                </div> -->

                <div class="flex flex-col">
                    <label for="offerOnStatus" class="text-stone-600 text-sm font-medium">Offer on</label>
            
                    <select id="status" formControlName="offerOnStatus" class="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                        (change)="getOfferData()"
                        >
                        <option name="None" value="null">None</option>
                        <option name="free" value="0">CALL</option>
                        <option name="paid" value="1">CHAT</option>
                        <option name="recharge-discount" value="2">recharge-discount</option>
                    </select>
                </div>

                <div class="flex flex-col">
                    <label for="offerStatus" class="text-stone-600 text-sm font-medium">Offer Status</label>
            
                    <select id="status" formControlName="offerStatus" class="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                        (change)="getOfferData()"
                        >
                        <option name="None" value="null">None</option>
                        <option name="Approved" value="1">New</option>
                        <option name="Not Approved" value="2">Open</option>
                        <option name="Not Approved" value="3">Used</option>
                        <option name="Not Approved" value="4">Expired</option>
                    </select>
                </div>

                <div class="flex flex-col">
                    <label for="freeOrPaidStatus" class="text-stone-600 text-sm font-medium">Free/Paid</label>
            
                    <select id="status" formControlName="freeOrPaidStatus" class="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                        (change)="getOfferData()"
                        >
                        <option name="None" value="null">None</option>
                        <option name="free" value="1">Free</option>
                        <option name="paid" value="2">Paid</option>
                    </select>
                </div>

            </div>
    
            <div class="mt-6 grid w-full grid-cols-2 justify-end space-x-4 md:flex">
                <button class="active:scale-95 rounded-lg bg-blue-600 px-8 py-2 font-medium text-white outline-none focus:ring hover:opacity-90" (click)="resetFilter()">Reset</button>
            </div>
        </div>
  
    </form>
</div>

  


<div class=" mx-auto">
    <!-- <form [formGroup]="callLogData">
        <div class="flex justify-center  w-full mt-3 mb-3">
            <div class="grid grid-cols-4 items-center gap-3 w-full">
                <div class="md:col-auto col-span-12">
                    <input type="text" class=" border-gray-200 py-1 px-2 w-full border-2" placeholder="Search ..."
                    (input)="search($event)" >
                </div>
                <div class="md:col-span-1  col-span-12">
                    <ng-select [items]="callLogTypeFilter" placeholder="Filter By" bindLabel="name" bindValue="value"
                        formControlName="filter" class="w-full" (change)="getCallLogsData()">
                    </ng-select>
                </div>


            </div>
        </div>
    </form> -->

    <div class="flex flex-col">
        <div class="overflow-x-auto shadow-md sm:rounded-lg">
            <div class="inline-block min-w-full align-middle">
                <div class="overflow-hidden ">
                    <table class="mt-3 min-w-full divide-y divide-gray-200 table-fixed ">
                        <thead class="bg-gray-100 ">
                            <tr>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Id</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    status</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    offerType</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Free/Paid</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Offer Image</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Thumbnail Imgage</th>

                            </tr>
                        </thead>
                        <tbody *ngIf="scratchCardDataList?.result?.length!==0;else noData"
                            class=" bg-white divide-y divide-gray-200 ">
                            <tr *ngFor="let item of scratchCardDataList?.result"
                                class="hover:bg-gray-100 cursor-pointer">
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.id }}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.offerStatus }}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.offerType }}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.FreeOrPaid }}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-black break-words text-left"
                                    >
                                    <img src={{item?.image1}}  alt="offerImage" class="h-14">
                                </td>

                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    <img src={{item?.thumbnailImg}}  alt="offerImage" class="h-14">
                                </td>
                            </tr>

                        </tbody>
                        <ng-template #noData>
                            <tbody>
                                <tr>
                                    <td colspan="12" class="text-center" style="font-size: 14px">
                                        No data available
                                    </td>
                                </tr>
                            </tbody>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- <mat-paginator [length]="callHistoryList?.totalPage" [pageSize]="1" hidePageSize="true" onchange="getData()"
    (page)="onPaginateChange($event)">
</mat-paginator> -->
<div class="flex justify-between p-2">
    <div>
        <input #titleInput class="border-gray-200 p-1 border-2 w-20" placeholder="Page No." (keydown.enter)="handleGoto(titleInput.value)">
        <button (click)="handleGoto(titleInput.value)"
            class="border-gray-200 p-1 border-2 bg-slate-300 hover:bg-slate-500">Go</button>
    </div>
    <div >
        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" (click)="exportexcel()"
            >Export to
            Excel</button>
    </div>
    <div>
        <mat-paginator [length]="scratchCardDataList?.totalPage" [pageSize]="1" hidePageSize="true" pageIndex={{pageRef}}
            (page)="onPaginateChange($event)">
        </mat-paginator>
    </div>
</div>




  

