<!-- <div class="mt-3 mb-5">
    <form [formGroup]="txnData">
        <div class="rounded-xl border border-gray-200 bg-slate-100 p-6 shadow-lg">


            <div class="flex">
                <div class="flex-1 flex items-end text-sm font-medium text-red-600">
                    <a class="block rounded-lg bg-gradient-to-r from-orange-500 to-rose-500 p-0.5 " href="#">
                        <div class="flex items-center rounded-md border border-gray-100 bg-white px-2 py-1 shadow-lg"> 
                          <img class="h-10 w-10 rounded-full object-cover" [src]="txnReportList?.customerData?.customerImg" alt="cusotmer Image" />
                          <div class="ml-4 w-72">
                            <p class="text-sm font-semibold">{{txnReportList?.customerData?.FullName }}</p>
                            <p class="text-sm text-gray-400 font-semibold">{{txnReportList?.customerData?.MobileNo }} | <span class="text-green-600">Balance: ₹ {{txnReportList?.customerData?.balance }}</span> /-</p> 
                          </div>
                        </div>
                      </a>
                      
                </div>
                <div class="flex-1 text-left "><h2 class="text-stone-700 text-xl font-bold mt-3">Apply filters</h2></div>
            </div>
            
        <div class="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
            <div class="flex flex-col">
            <label for="name" class="text-stone-600 text-sm font-medium" >Search</label>
            <input type="text" id="name" (input)="search($event)" placeholder="search by txn_id, payment_id..." class="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" />
            </div>

            <div class="flex flex-col">
                <label for="paymentStatus" class="text-stone-600 text-sm font-medium">Call Status</label>
        
                <select id="status" formControlName="paymentStatus" class="mt-2 block w-full rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    (change)="getTxnData()"
                    >
                    <option name="None" value="null">None</option>
                    <option name="Success" value="0">Success</option>
                    <option name="Declined" value="1">Declined</option>
                </select>
            </div>
        </div>
    
        <div class="mt-6 grid w-full grid-cols-2 justify-end space-x-4 md:flex">
            <button class="active:scale-95 rounded-lg bg-blue-600 px-8 py-2 font-medium text-white outline-none focus:ring hover:opacity-90" (click)="resetFilterFn()">Reset</button>
        </div>
        </div>
    </form>
</div> -->
  

<div class=" mx-auto">
    <form [formGroup]="txnData" >
        <div class="flex justify-center  w-full mt-5 mb-2" >
            <div class="grid grid-cols-4 items-center gap-3 w-full">
                <div class="md:col-span-1  col-span-12">

                     <ng-select [items]="filter" placeholder="Sort By" bindLabel="name" bindValue="value"
                        formControlName="type" class="w-full" (change)="getTxnData()">
                    </ng-select>

                </div>
                <div class="md:col-auto col-span-12">
                    <input type="text" class="border-2 py-1 px-2 w-full" ngxDaterangepickerMd
                        [showCustomRangeLabel]="true" [(ngModel)]="selected" [alwaysShowCalendars]="true"
                        [ranges]="ranges" [linkedCalendars]="true" [isInvalidDate]="isInvalidDate"
                        formControlName="StartDate" [showClearButton]="true" placeholder="Select date range "
                        (change)="choosedDate($event)" />
                </div>
                <div class="md:col-span-1  col-span-12">

                    <ng-select [items]="option" placeholder="Sort By" bindLabel="name" bindValue="value"
                        formControlName="status" class="w-full" (change)="getTxnData()">
                    </ng-select>

                </div>
                <div class="md:col-auto col-span-12">
                    <input type="text" class=" border-gray-200 py-1 px-2 w-full border-2" placeholder="Search ..."
                        (input)="search($event)">
                </div>

            </div>
        </div>
    </form>

    <div class="flex flex-col">
        <div class="overflow-x-auto shadow-md sm:rounded-lg">
            <div class="inline-block min-w-full align-middle">
                <div class="overflow-hidden ">
                    <table class="mt-3min-w-full divide-y divide-gray-200 table-fixed ">
                        <thead class="bg-gray-100 ">
                            <tr>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Transaction Id</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Type</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    PaymentId</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Date/Time</th>
                                 <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Amount</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    GST (%)</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    GST Amount</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Total Amount</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Discount (%)</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Discount Amount</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Net Amount</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Payment Status</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Remarks</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="paymentLogList?.length!==0;else noData"
                            class=" bg-white divide-y divide-gray-200 ">
                            <tr *ngFor="let item of paymentLogList?.txnReport"
                                class="hover:bg-gray-100 cursor-pointer">
                                <td 
                                    class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.TxnId }}</td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.TxnType }}</td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.PaymentId ? item?.PaymentId : "N/A"}}</td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.TxnRefNo | date:"short"}}</td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.Amount}}</td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.GSTPer}}</td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.GSTAmt}}</td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.TotalAmount}}</td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.DiscPer}}</td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.DiscAmt}}</td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.NetAmt}}</td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    <div [ngClass]="item?.PaymentStatus=='Success' ? 'text-green-500'
                                    : 'text-red-500'">
                                        {{ item?.PaymentStatus }}
                                    </div>
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                    {{item?.Remarks}}</td>
                            </tr>

                        </tbody>
                        <ng-template #noData>
                            <tbody>
                                <tr>
                                    <td colspan="12" class="text-center" style="font-size: 14px">
                                        No data available
                                    </td>
                                </tr>
                            </tbody>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>


<div class="flex justify-between p-2">
    <div>
        <!-- <input #titleInput class="border-gray-200 p-1 border-2 w-20" placeholder="Page No." (keydown.enter)="handleGoto(titleInput.value)">
        <button (click)="handleGoto(titleInput.value)"
            class="border-gray-200 p-1 border-2 bg-slate-300 hover:bg-slate-500">Go</button> -->
    </div>
    <div >
        <!-- <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" *ngIf="!isAdmin()"
            (click)="exportexcel()">Export to
            Excel</button> -->
    </div>
    <div>
        <!-- <mat-paginator [length]="paymentLogList?.totalPage" [pageSize]="1" hidePageSize="true" 
            onchange="getTxnData()" (page)="onPaginateChange($event)">
        </mat-paginator> -->
        <mat-paginator [length]="paymentLogList?.totalPage" [pageSize]="1" hidePageSize="true" onchange="getTxnData()"
            (page)="onPaginateChange($event)">
        </mat-paginator>
    </div>
</div>

<!-- <ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Call ID: {{modalData?.order?.id}}</h4>
    </div>
    <div class="modal-content">
        <div class="p-2">
            <table class="table-auto text-center">
                <tr>
                    <th class="border-r-2">Call Date</th>
                    <td> {{modalData?.order?.orderInitiate | date:"longDate"}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">Call Time</th>
                    <td>{{modalData?.order?.orderInitiate | date:"shortTime"}}</td>
                </tr>

                <tr>
                    <th class="border-r-2">Customer Name</th>
                    <td>{{modalData?.customerInfo?.firstname+" "+modalData?.customerInfo?.lastname}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">Mobile</th>
                    <td>{{modalData?.customerInfo?.mobileno}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">Astrologer Name</th>
                    <td> {{modalData?.astroname}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">Call Status</th>
                    <td [ngClass]="modalData?.order?.orderStatus=='Completed'?'text-green-500'
                    :modalData?.order?.orderStatus=='Cancelled'?'text-red-500':
                    modalData?.order?.orderStatus=='Refunded'?'text-blue-500':'text-yellow-500'">
                        {{modalData?.order?.orderStatus}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">Call Rate</th>
                    <td>{{modalData?.charges?.astroRate}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">Call Duration</th>
                    <td>{{modalData?.order?.orderDuration}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">Total Charges</th>
                    <td> {{modalData?.charges?.totalCharges}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">Company Share</th>
                    <td> {{modalData?.charges?.compAmt}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">Astrologer Share</th>
                    <td> {{modalData?.charges?.astroAmt}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">PG(%)</th>
                    <td>{{modalData?.charges?.pgPer}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">PG Amount</th>
                    <td>{{modalData?.charges?.pgAmt}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">TDS(%)</th>
                    <td>{{modalData?.charges?.TDSPer}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">TDS Amount</th>
                    <td>{{modalData?.charges?.TDSAmt}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">Astrologer Net Share</th>
                    <td>{{modalData?.charges?.astroTotalAmt}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">Remarks</th>
                    <td>{{modalData?.order?.remarks ?modalData?.order?.remarks:"No Remarks"}}</td>
                </tr>
                <tr>
                    <th class="border-r-2">Recording URL</th>
                    <td *ngIf="modalData?.order?.recordinURL;else noData">
                        <a href={{modalData?.order?.recordinURL}} target="_blank"
                            class="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 w-2 rounded text-xs ">View
                            Recording</a>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</ng-template> -->


  

