import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { AdminService } from 'src/app/shared/services/admin-api/admin-api.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { NotifierService } from 'src/app/shared/services/toaster.service';
import { Buffer } from 'buffer'
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-astro-graph',
  templateUrl: './astro-graph.component.html',
  styleUrls: ['./astro-graph.component.css']
})
export class AstroGraphComponent {
  modelDate = new Date();
  missedOrdercharts: EChartsOption;
  completedPaidOrders: EChartsOption;
  attendedOrders: EChartsOption;
  missedOrders: EChartsOption;
  busyTime: EChartsOption;
  onlineTime: EChartsOption;
  paidMinutesData: EChartsOption; 
  freeMinutesData: EChartsOption;
  repeatUserData: EChartsOption;
  dashData: any;
  values: any;
  dates: any;
  astroId = null;
  data: any;
  constructor(
    private _AdminService: AdminService,
    private _NotifierService: NotifierService,
    private _SpinnerService: SpinnerService,
    private _ActivatedRoute: ActivatedRoute
  ) {
    this.missedOrdercharts = {};
    this.completedPaidOrders = {};
    this.attendedOrders = {};
    this.missedOrders = {};
    this.busyTime = {};
    this.onlineTime = {};
    this.paidMinutesData = {};
    this.freeMinutesData = {};
    this.repeatUserData = {};
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe((params) => {
      this.astroId = params['astroid'];
    });
    // this.fetchDash();
  }
  
  onOpenCalendar(container: any) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  getDate(date: any) {
    if (date) {
      this._SpinnerService.setState(true);
      this.fetchDash(moment(date).format("MM/DD/YYYY"));
      this._SpinnerService.setState(false);
    }
  }

  // fetchDash(graphDate: any = null) {

  //   this._SpinnerService.setState(true);
  //   this._AdminService.fetchDashboard({ graphDate: graphDate }).then((data) => {

  //     if (data?.status) {
  //       this.dashData = data?.data;
  //       this.dates = data?.data?.userData?.userDates;
  //       this.values = data?.data?.userData?.userCount;
  //       this.charts = {
  //         title: {
  //           text: 'Missed Paid Orders'
  //         },
  //         tooltip: {
  //           trigger: 'axis',
  //           axisPointer: {
  //             animation: false
  //           }
  //         },
  //         yAxis: {
  //           type: 'value',
  //           name: "Orders"
  //         },
  //         xAxis: {
  //           type: 'category',
  //           data: data?.data?.userData?.userDates,
  //           name: "Date"
  //         },
  //         series: [
  //           {
  //             data: data?.data?.userData?.userCount,
  //             type: 'line'
  //           }
  //         ]
  //       };



  //     }
  //     else
  //       this._NotifierService.showError("Some Error Occurred")
  //   })
  //   this._SpinnerService.setState(false);
  // }

  fetchDash(graphDate: any = null) {

    this._SpinnerService.setState(true);
    this._AdminService.getAstroGraphData({AstroId: this.astroId}).then((data) => {

      if (data?.status) {
        this.dashData = data?.data;
        this.dates = data?.data?.userDates;
        this.values = data?.data?.userData?.userCount;
        this.data = data?.data;
        this.missedOrdercharts = {
          title: {
            text: 'Missed Paid Orders'
          },
          color: 'red',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false
            }
          },
          yAxis: {
            type: 'value',
            name: "Orders"
          },
          xAxis: {
            type: 'category',
            data: data?.data?.missedPaidData?.order_date,
            name: "Date",
            // boundaryGap: false
            axisLabel: {
              rotate: 45,
              interval: 0 // Display all labels on the X-axis without skipping
            }
          },
          series: [
            {
              data: data?.data?.missedPaidData?.no_of_orders,
              type: 'bar'
            }
          ]
        };

        this.completedPaidOrders = {
          title: {
            text: 'Completed Paid Orders'
          },
          color: 'green',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false
            }
          },
          yAxis: {
            type: 'value',
            name: "Orders"
          },
          xAxis: {
            type: 'category',
            data: data?.data?.completedPaidData?.order_date,
            name: "Date",
            axisLabel: {
              rotate: 45,
              interval: 0 // Display all labels on the X-axis without skipping
            }
          },
          series: [
            {
              data: data?.data?.completedPaidData?.no_of_orders,
              type: 'bar'
            }
          ]
        };

        this.attendedOrders = {
          title: {
            text: 'Attended Orders'
          },
          color: 'blue',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false
            }
          },
          yAxis: {
            type: 'value',
            name: "Orders"
          },
          xAxis: {
            type: 'category',
            data: data?.data?.attendedData?.order_date,
            name: "Date",
            axisLabel: {
              rotate: 45,
              interval: 0 // Display all labels on the X-axis without skipping
            }
          },
          series: [
            {
              data: data?.data?.attendedData?.no_of_orders,
              type: 'bar'
            }
          ]
        };

        this.missedOrders = {
          title: {
            text: 'Missed Orders'
          },
          color: 'red',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false
            }
          },
          yAxis: {
            type: 'value',
            name: "Orders"
          },
          xAxis: {
            type: 'category',
            data: data?.data?.missedData?.order_date,
            name: "Date",
            axisLabel: {
              rotate: 45,
              interval: 0 // Display all labels on the X-axis without skipping
            }
          },
          series: [
            {
              data: data?.data?.missedData?.no_of_orders,
              type: 'bar'
            }
          ]
        };

        this.busyTime = {
          title: {
            text: 'Busy Time'
          },
          color: 'Yellow',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false
            }
          },
          yAxis: {
            type: 'value',
            name: "Orders"
          },
          xAxis: {
            type: 'category',
            data: data?.data?.busyTimeDatas?.order_date,
            name: "Date",
            axisLabel: {
              rotate: 45,
              interval: 0 // Display all labels on the X-axis without skipping
            }
          },
          series: [
            {
              data: data?.data?.busyTimeDatas?.no_of_orders,
              type: 'bar'
            },
            {
              data: data?.data?.busyTimeDatas?.no_of_orders,
              type: 'bar'
            }
          ]
        };

        this.onlineTime = {
          title: {
            text: 'Online Time'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false
            }
          },
          yAxis: {
            type: 'value',
            name: "Orders"
          },
          xAxis: {
            type: 'category',
            data: data?.data?.onlineData?.order_date,
            name: "Date"
          },
          series: [
            {
              data: data?.data?.onlineData?.no_of_orders,
              type: 'bar'
            }
          ]
        };

        this.paidMinutesData = {
          title: {
            text: 'Paid Minutes'
          },
          color: 'orange',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false
            }
          },
          yAxis: {
            type: 'value',
            name: "Orders"
          },
          xAxis: {
            type: 'category',
            data: data?.data?.paidMinutesDatas?.order_date,
            name: "Date",
            axisLabel: {
              rotate: 45,
              interval: 0 // Display all labels on the X-axis without skipping
            }
          },
          series: [
            {
              data: data?.data?.paidMinutesDatas?.no_of_orders,
              type: 'bar'
            }
          ]
        };

        this.freeMinutesData = {
          title: {
            text: 'Free Minutes'
          },
          color: 'orange',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false
            }
          },
          yAxis: {
            type: 'value',
            name: "Orders"
          },
          xAxis: {
            type: 'category',
            data: data?.data?.freeMinutesDatas?.order_date,
            name: "Date",
            axisLabel: {
              rotate: 45,
              interval: 0 // Display all labels on the X-axis without skipping
            }
          },
          series: [
            {
              data: data?.data?.freeMinutesDatas?.no_of_orders,
              type: 'bar'
            }
          ]
        };

        this.repeatUserData = {
          title: {
            text: 'Repeat User'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false
            }
          },
          yAxis: {
            type: 'value',
            name: "No of Users"
          },
          xAxis: {
            type: 'category',
            data: data?.data?.repeatUsersData?.order_date,
            name: "Date"
          },
          series: [
            {
              data: data?.data?.repeatUsersData?.no_of_orders,
              type: 'bar'
            }
          ]
        };

      }
      else
        this._NotifierService.showError("Some Error Occurred")
    })
    this._SpinnerService.setState(false);
  }
}
