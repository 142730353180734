<div class="bg-slate-100 my-3 p-8 rounded-lg shadow-md w-full">
    <h1 class="text-2xl font-semibold mb-4">Overall Records</h1>
    <div class="flex w-full">
        <div class="grid grid-cols-1 w-72 gap-4 mr-2" *ngIf="astroRecord?.overallRecord;else noData">
            <div class="bg-blue-100 p-4 rounded-lg ">
                <p class="text-lg font-semibold text-blue-700">Joined Date </p>
                <p class="text-xl font-bold text-blue-900">{{astroRecord?.astroInfo}}</p>
            </div>
            <div class="bg-fuchsia-100 p-4 rounded-lg">
                <p class="text-lg font-semibold text-fuchsia-700">Total Amount Earned</p>
                <p class="text-xl font-bold text-fuchsia-900">{{astroRecord?.overallRecord?.totalAmount}}</p>
            </div>
        </div>
        <div class="grid grid-cols-1 w-full gap-4 mr-2">
            <span *ngFor="let item of astroRecord?.overallRecord?.overall">
                <div *ngIf="item?.orderType=='UCHAT'" class="grid grid-cols-6">
                    <div class="bg-green-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-green-700">Total Chat Count</p>
                        <p class="text-xl font-bold text-green-900">{{item?.totalCount}}</p>
                    </div>
                    <div class="bg-red-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-red-700">Total Free Chats</p>
                        <p class="text-xl font-bold text-red-900">{{item?.freeCount}}</p>
                    </div>
                    <div class="bg-pink-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-pink-700">Total Chat Minutes</p>
                        <p class="text-xl font-bold text-pink-900">{{item?.totalDuration* 1000 | date:'mm'}}</p>
                    </div>
                    <div class="bg-sky-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-sky-700">Total Free Chat Minutes</p>
                        <p class="text-xl font-bold text-sky-900">{{item?.freeSeconds* 1000 | date:'mm'}}</p>
                    </div>
                    <div class="bg-indigo-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-indigo-700">Total Paid Chats</p>
                        <p class="text-xl font-bold text-indigo-900">{{item?.paidCount}}</p>
                    </div>
                    <div class="bg-pink-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-pink-700">Total Paid Chat Minutes</p>
                        <p class="text-xl font-bold text-pink-900">{{item?.paidSeconds* 1000 | date:'mm'}}</p>
                    </div>
                </div>
                <div *ngIf="item?.orderType=='UCALL'" class="flex" class="grid grid-cols-6">
                    <div class="bg-yellow-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-yellow-700">Total Call Count</p>
                        <p class="text-xl font-bold text-yellow-900">{{item?.totalCount}}</p>
                    </div>
                    <div class="bg-purple-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-purple-700">Total Free Calls</p>
                        <p class="text-xl font-bold text-purple-900">{{item?.freeCount}}</p>
                    </div>

                    <div class="bg-red-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-red-700">Total Calls Minutes</p>
                        <p class="text-xl font-bold text-red-900">{{item?.totalDuration* 1000 | date:'mm'}}</p>
                    </div>
                    <div class="bg-orange-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-orange-700">Total Free Call Minutes</p>
                        <p class="text-xl font-bold text-orange-900">{{item?.freeSeconds* 1000 | date:'mm'}}</p>
                    </div>
                    <div class="bg-pink-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-pink-700">Total Paid Calls</p>
                        <p class="text-xl font-bold text-pink-900">{{item?.paidCount}}</p>
                    </div>
                    <div class="bg-indigo-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-indigo-700">Total Paid Call Minutes</p>
                        <p class="text-xl font-bold text-indigo-900">{{item?.paidSeconds* 1000 | date:'mm'}}</p>
                    </div>
                </div>

            </span>
        </div>
    </div>
</div>
<div class="bg-slate-100 my-3 p-8 rounded-lg shadow-md w-full">
    <h1 class="text-2xl font-semibold mb-4">Monthly Records</h1>
    <div class="flex w-full">
        <div class="grid grid-cols-1 w-72 gap-4 mr-2" *ngIf="astroRecord?.monthlyRecord;else noData">
            <div class="bg-blue-100 p-4 rounded-lg ">
                <p class="text-lg font-semibold text-blue-700">Joined Date </p>
                <p class="text-xl font-bold text-blue-900">{{astroRecord?.astroInfo}}</p>
            </div>
            <div class="bg-fuchsia-100 p-4 rounded-lg">
                <p class="text-lg font-semibold text-fuchsia-700">Total Amount Earned</p>
                <p class="text-xl font-bold text-fuchsia-900">{{astroRecord?.monthlyRecord?.totalAmount}}</p>
            </div>
        </div>
        <div class="grid grid-cols-1 w-full gap-4 mr-2">
            <span *ngFor="let item of astroRecord?.monthlyRecord?.month">
                <div *ngIf="item?.orderType=='UCHAT'" class="grid grid-cols-6">
                    <div class="bg-green-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-green-700">Total Chat Count</p>
                        <p class="text-xl font-bold text-green-900">{{item?.totalCount}}</p>
                    </div>
                    <div class="bg-red-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-red-700">Total Free Chats</p>
                        <p class="text-xl font-bold text-red-900">{{item?.freeCount}}</p>
                    </div>
                    <div class="bg-pink-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-pink-700">Total Chat Minutes</p>
                        <p class="text-xl font-bold text-pink-900">{{item?.totalDuration* 1000 | date:'mm'}}</p>
                    </div>
                    <div class="bg-sky-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-sky-700">Total Free Chat Minutes</p>
                        <p class="text-xl font-bold text-sky-900">{{item?.freeSeconds* 1000 | date:'mm'}}</p>
                    </div>
                    <div class="bg-indigo-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-indigo-700">Total Paid Chats</p>
                        <p class="text-xl font-bold text-indigo-900">{{item?.paidCount}}</p>
                    </div>
                    <div class="bg-pink-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-pink-700">Total Paid Chat Minutes</p>
                        <p class="text-xl font-bold text-pink-900">{{item?.paidSeconds* 1000 | date:'mm'}}</p>
                    </div>
                </div>
                <div *ngIf="item?.orderType=='UCALL'" class="flex" class="grid grid-cols-6">
                    <div class="bg-yellow-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-yellow-700">Total Call Count</p>
                        <p class="text-xl font-bold text-yellow-900">{{item?.totalCount}}</p>
                    </div>
                    <div class="bg-purple-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-purple-700">Total Free Calls</p>
                        <p class="text-xl font-bold text-purple-900">{{item?.freeCount}}</p>
                    </div>

                    <div class="bg-red-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-red-700">Total Calls Minutes</p>
                        <p class="text-xl font-bold text-red-900">{{item?.totalDuration* 1000 | date:'mm'}}</p>
                    </div>
                    <div class="bg-orange-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-orange-700">Total Free Call Minutes</p>
                        <p class="text-xl font-bold text-orange-900">{{item?.freeSeconds* 1000 | date:'mm'}}</p>
                    </div>
                    <div class="bg-pink-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-pink-700">Total Paid Calls</p>
                        <p class="text-xl font-bold text-pink-900">{{item?.paidCount}}</p>
                    </div>
                    <div class="bg-indigo-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-indigo-700">Total Paid Call Minutes</p>
                        <p class="text-xl font-bold text-indigo-900">{{item?.paidSeconds* 1000 | date:'mm'}}</p>
                    </div>
                </div>

            </span>
        </div>
    </div>
</div>

<div class="bg-slate-100 my-3 p-8 rounded-lg shadow-md w-full">
    <h1 class="text-2xl font-semibold mb-4">Today's Records</h1>
    <div class="flex w-full">
        <div class="grid grid-cols-1 w-72 gap-4 mr-2" *ngIf="astroRecord?.todayRecord;else noData">
            <div class="bg-blue-100 p-4 rounded-lg ">
                <p class="text-lg font-semibold text-blue-700">Joined Date </p>
                <p class="text-xl font-bold text-blue-900">{{astroRecord?.astroInfo}}</p>
            </div>
            <div class="bg-fuchsia-100 p-4 rounded-lg">
                <p class="text-lg font-semibold text-fuchsia-700">Total Amount Earned</p>
                <p class="text-xl font-bold text-fuchsia-900">{{astroRecord?.todayRecord?.totalAmount}}</p>
            </div>
        </div>
        <div class="grid grid-cols-1 w-full gap-4 mr-2">
            <span *ngFor="let item of astroRecord?.todayRecord?.today">
                <div *ngIf="item?.orderType=='UCHAT'" class="grid grid-cols-6">
                    <div class="bg-green-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-green-700">Total Chat Count</p>
                        <p class="text-xl font-bold text-green-900">{{item?.totalCount}}</p>
                    </div>
                    <div class="bg-red-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-red-700">Total Free Chats</p>
                        <p class="text-xl font-bold text-red-900">{{item?.freeCount}}</p>
                    </div>
                    <div class="bg-pink-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-pink-700">Total Chat Minutes</p>
                        <p class="text-xl font-bold text-pink-900">{{item?.totalDuration* 1000 | date:'mm'}}</p>
                    </div>
                    <div class="bg-sky-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-sky-700">Total Free Chat Minutes</p>
                        <p class="text-xl font-bold text-sky-900">{{item?.freeSeconds* 1000 | date:'mm'}}</p>
                    </div>
                    <div class="bg-indigo-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-indigo-700">Total Paid Chats</p>
                        <p class="text-xl font-bold text-indigo-900">{{item?.paidCount}}</p>
                    </div>
                    <div class="bg-pink-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-pink-700">Total Paid Chat Minutes</p>
                        <p class="text-xl font-bold text-pink-900">{{item?.paidSeconds* 1000 | date:'mm'}}</p>
                    </div>
                </div>
                <div *ngIf="item?.orderType=='UCALL'" class="flex" class="grid grid-cols-6">
                    <div class="bg-yellow-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-yellow-700">Total Call Count</p>
                        <p class="text-xl font-bold text-yellow-900">{{item?.totalCount}}</p>
                    </div>
                    <div class="bg-purple-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-purple-700">Total Free Calls</p>
                        <p class="text-xl font-bold text-purple-900">{{item?.freeCount}}</p>
                    </div>

                    <div class="bg-red-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-red-700">Total Calls Minutes</p>
                        <p class="text-xl font-bold text-red-900">{{item?.totalDuration* 1000 | date:'mm'}}</p>
                    </div>
                    <div class="bg-orange-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-orange-700">Total Free Call Minutes</p>
                        <p class="text-xl font-bold text-orange-900">{{item?.freeSeconds* 1000 | date:'mm'}}</p>
                    </div>
                    <div class="bg-pink-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-pink-700">Total Paid Calls</p>
                        <p class="text-xl font-bold text-pink-900">{{item?.paidCount}}</p>
                    </div>
                    <div class="bg-indigo-100 p-4 rounded-lg">
                        <p class="text-lg font-semibold text-indigo-700">Total Paid Call Minutes</p>
                        <p class="text-xl font-bold text-indigo-900">{{item?.paidSeconds* 1000 | date:'mm'}}</p>
                    </div>
                </div>

            </span>
        </div>
    </div>
</div>

<ng-template #noData>
    <tbody>
        <tr>
            <td colspan="12" class="text-center" style="font-size: 14px">
                No data available
            </td>
        </tr>
    </tbody>
</ng-template>