import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from 'src/app/shared/services/admin-api/admin-api.service';
import { NotifierService } from 'src/app/shared/services/toaster.service';
import * as moment from 'moment';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import option from '../../../../assets/constants/ngSelectvalues';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import * as XLSX from 'xlsx';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/shared/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.css']
})
export class UserActivityComponent {
  getActivityData: FormGroup
  activityList: any = []
  ranges: any = {
    Today: [new Date(), new Date()],
    Yesterday: [
      moment().utc().subtract(1, 'days'),
      moment().subtract(1, 'days').local(),
    ],
    'Last 7 Days': [moment().utc().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().utc().subtract(29, 'days'), moment()],
    'This Month': [
      moment().utc().startOf('month'),
      moment().utc().endOf('month'),
    ],
    'Last Month': [
      moment().utc().subtract(1, 'month').startOf('month'),
      moment().utc().subtract(1, 'month').endOf('month'),
    ],
  };
  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];
  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };
  startDate = new Date();
  selected: any = { start: this.startDate, end: this.startDate };
  alwaysShowCalendars: boolean;
  page: number = 1;
  modelChanged: Subject<any> = new Subject<any>();
  option = option.activityFilter;


  constructor(
    private _AdminService: AdminService,
    private _NotifierService: NotifierService,
    private _FormBuilder: FormBuilder,
    private _SpinnerService: SpinnerService,
    private modalService: BsModalService,
    private _ActivatedRoute: ActivatedRoute,
    private _CommonService: CommonService
  ) {
    this.alwaysShowCalendars = true;
    this.getActivityData = this._FormBuilder.group({
      // from: [this.startDate.setUTCHours(0, 0, 0, 0).valueOf() + new Date().getTimezoneOffset() * 1000 * 60],
      // to: [this.startDate.setUTCHours(23, 59, 59, 999).valueOf() + new Date().getTimezoneOffset() * 1000 * 60],
      from: null,
      to: null,
      type: [null],
      page: this.page,
      search: [''],
    });

  }

  search(event: any) {
    this.modelChanged.next({ search: event.target.value });
  }

  choosedDate(event: any) {
    this.getActivityData
      .get('from')
      ?.setValue(new Date(event?.startDate?.$d).valueOf() + new Date().getTimezoneOffset() * 1000 * 60);
    this.getActivityData
      .get('to')
      ?.setValue(new Date(event?.endDate?.$d).valueOf() + new Date().getTimezoneOffset() * 1000 * 60);
    this.fetchActivityData();
  }

  fetchActivityData() {
    this._AdminService
      .getUserCountActivity(this.getActivityData.value)
      .then((data) => {
        if (data?.status) {
          this.activityList = data?.data
        }
      });
  }

  ngOnInit(): void {
    this.modelChanged
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((val) => {
        this.getActivityData.get('search')?.setValue(val?.search);
        this.fetchActivityData();
      });
    this.fetchActivityData();
    // this.getMasterdata('refund reason')
  }

  onPaginateChange($: any) {
    this.getActivityData.get('page')?.setValue($.pageIndex + 1);
    this.fetchActivityData();
  }
}
