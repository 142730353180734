<div class="flex justify-between mb-5">
    <h1>
        Manage Astrologer Registration (Application)
    </h1>
    <div class="">
        <input type="text" class="mx-2 border-2 border-slate-300 px-2 py-1 rounded" placeholder="Search..."
            (input)="search($event)">
        <button (click)="openModal(template)"
            class="mx-2 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">Create
            new Registration</button>
    </div>

</div>

<div class="flex flex-col">
    <div class="overflow-x-auto shadow-md sm:rounded-lg">
        <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden ">
                <table class="min-w-full divide-y divide-gray-200 table-fixed ">
                    <thead class="bg-gray-100 ">
                        <tr>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                #RefNo</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                #Date</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                #Time</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Full Name</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Mobile No</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Experience</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="astroList?.data?.length!==0;else noData" class=" bg-white divide-y divide-gray-200 ">
                        <tr *ngFor="let astroInfo of astroList?.data" class="hover:bg-gray-100 cursor-pointer">
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 break-words">
                                {{astroInfo?.RefNo}}
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                {{astroInfo?.EntryDate}}
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                {{astroInfo?.EntryTime}}
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                {{astroInfo?.FullName}}
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                {{astroInfo?.MobileNo}}
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                {{astroInfo?.Experience}}
                            </td>
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                                <div class="flex text-white">
                                    <a class="p-1 bg-blue-400  hover:bg-blue-500 rounded m-1" routerLink="../viewAstro"
                                        [queryParams]="{astroid:astroInfo?.astroid,type:'R'}" role="link">
                                        <mat-icon>edit</mat-icon>
                                    </a>
                                    <button class="p-1 bg-red-400  hover:bg-red-500 rounded m-1"
                                        (click)="deleteAstro(astroInfo?.astroid)" *ngIf="getPermisson('delete')">
                                        <mat-icon>delete_outline</mat-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <ng-template #noData>
                        <tbody>
                            <tr>
                                <td colspan="12" class="text-center" style="font-size: 14px">
                                    No data available
                                </td>
                            </tr>
                        </tbody>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="md:col-span-1 col-span-12 mt-4">
    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-2 rounded" (click)="exportexcel()">
        Export to Excel
    </button>
</div>
<mat-paginator [length]="astroList?.totalPage" [pageSize]="20" hidePageSize="true" (page)="pagination($event)">
</mat-paginator>
<ng-template #template>
    <div class="modal-header flex justify-between p-0 m-0">
        <div></div>
        <div class="float-right">
            <button (click)="closeModal('Close')"><mat-icon>close</mat-icon></button>
        </div>
    </div>
    <div class="modal-content">
        <div class="p-5">
            <form [formGroup]="astroData" (ngSubmit)="onSubmit()" novalidate>
                <div class="grid grid-cols-1 items-center gap-3 w-full">

                    <div class="col-span-12 relative z-0 mb-6 w-full">
                        <label for="formFile" class="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
                            Image (Size::250px X 250px) &lt; 900mb</label>
                        <input
                            class="relative m-0 block w-fullskills min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                            type="file" id="formFile" (change)="fileupload($event)" accept=".webp" />
                    </div>
                </div>
                <div class="grid grid-cols-3 items-center gap-3 w-full">
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <input type="text" name="Fullname"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " formControlName="Fullname" />
                        <label for="Fullname"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Name
                        </label>
                    </div>
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <input type="text" name="slug"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder="" formControlName="Email" />
                        <label for="slug"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Email
                        </label>
                    </div>
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <ng-select [items]="country" bindValue="value" formControlName="CountryId" placeholder="Country"
                            bindLabel="name" name="CountryId" id="CountryId" class="text-sm p-0.5">
                        </ng-select>
                        <!-- <input type="text" name="slug"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder="" formControlName="CountryId" />
                        <label for="slug"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Country
                        </label> -->
                    </div>
                </div>
                <div class="grid grid-cols-4 items-center gap-3 w-full">
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <input type="number" name="description"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " formControlName="MobileNo" />
                        <label for="description"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            MobileNo
                        </label>
                    </div>
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <ng-select [items]="gender" bindValue="value" formControlName="Gender" placeholder="Gender"
                            bindLabel="name" name="Gender" id="Gender" class="text-sm p-0.5">
                        </ng-select>
                    </div>
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <input type="date" name="slug"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder="" formControlName="DOB" />
                        <label for="slug"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            DOB
                        </label>
                    </div>
                    <div class="col-span-1 relative mb-6 w-full">
                        <ng-select [items]="skillList" bindValue="value" formControlName="PrimarySkillId"
                            placeholder="Prefferd Skills" bindLabel="name" name="PrimarySkillId" id="PrimarySkillId"
                            [multiple]="true" class="text-sm p-0.5">
                        </ng-select>
                    </div>
                </div>
                <div class="grid grid-cols-3 items-center gap-3 w-full">
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <ng-select [items]="languageList" bindValue="value" formControlName="LanguageId"
                            placeholder="Prefferd Skills" bindLabel="name" name="LanguageId" id="LanguageId"
                            [multiple]="true" class="text-sm p-0.5">
                        </ng-select>
                    </div>
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <input type="number" name="slug"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder="" formControlName="Experience" />
                        <label for="slug"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Experience
                        </label>
                    </div>
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <input type="text" name="city"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " formControlName="city" />
                        <label for="city"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Which city do you currently live in? *
                        </label>
                    </div>
                </div>
                <div class="grid grid-cols-3 items-center gap-3 w-full">
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <ng-select [items]="yesno" placeholder="Working on other online platform? *" bindLabel="name"
                            bindValue="name" formControlName="IsWorking" class="w-full text-sm p-0.5">
                        </ng-select>
                    </div>
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <input type="text" name="PlatformName"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " formControlName="PlatformName" />
                        <label for="PlatformName"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Platform Name
                        </label>
                    </div>
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <input type="number" name="Earning"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder="" formControlName="Earning" />
                        <label for="Earning"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Monthly Earning?
                        </label>
                    </div>

                </div>
                <div class="grid grid-cols-3 items-center gap-3 w-full">
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <input type="text" name="InterviewTime"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder="" formControlName="InterviewTime" />
                        <label for="InterviewTime"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            What is a suitable time for interview? *
                        </label>
                    </div>
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <input type="text" name="OnBoardComment"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder="" formControlName="OnBoardComment" />
                        <label for="OnBoardComment"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Why do you think we should onboard you? *
                        </label>
                    </div>
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <input type="int" name="HrsContributed"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder="" formControlName="HrsContributed" />
                        <label for="HrsContributed"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            How many hours you can contribute daily?
                        </label>
                    </div>
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <input type="text" name="SourceOfBusiness"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder="" formControlName="SourceOfBusiness" />
                        <label for="SourceOfBusiness"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Main source of business ( other than astrology)?
                        </label>
                    </div>
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <ng-select [items]="yesno" placeholder="Does Anyoune refreed you? *" bindLabel="name"
                            bindValue="name" formControlName="IsRefered" class="w-full p-0.5">
                        </ng-select>
                    </div>
                    <div class="col-span-1 relative z-0 mb-6 w-full">
                        <input type="text" name="ReferedPersonName"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder="" formControlName="ReferedPersonName" />
                        <label for="ReferedPersonName"
                            class="absolute text-md text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Name of the person who referred you?
                        </label>
                    </div>
                </div>
                <div class="grid grid-cols-1 items-center gap-3 w-full">
                    <div class="relative z-0 my-6 w-full group">
                        <textarea name="OnBoardDescP" id="OnBoardDescP"
                            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " formControlName="OnBoardDescP"></textarea>
                        <label for="OnBoardDescP"
                            class="absolute text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Bio Description
                        </label>
                    </div>
                </div>

                <div class="float-right p-3">
                    <button type="submit" data-te-ripple-init data-te-ripple-color="light" *ngIf="getPermisson('write')"
                        class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>