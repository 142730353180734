<div class="flex justify-between mb-5">
    <div>
        <h1>
            Manage Comments:
        </h1>
        <div class=" w-full">
            <div class="md:col-span-1  col-span-12">
                <ng-select [items]="option" placeholder="Sort By" bindLabel="name" bindValue="value" class="w-full"
                    (change)="setFilter($event)" [(ngModel)]="filter">
                </ng-select>
            </div>
        </div>

    </div>
    <div>
        <p>
            <b>
                Astrologer Name:
            </b>{{videoList?.astroInfo}}
        </p>
        <p>
            <b>
                Video Name:
            </b>{{videoList?.videoInfo}}
        </p>
    </div>
</div>



<form>
    <div class="flex flex-col">
        <div class="overflow-x-auto shadow-md sm:rounded-lg">
            <div class="inline-block min-w-full align-middle">
                <div class="overflow-hidden ">
                    <table class="min-w-full divide-y divide-gray-200 table-fixed ">
                        <thead class="bg-slate-800 ">
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-white uppercase ">
                                Video Id</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-white uppercase">
                                Customer Name</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-white uppercase">
                                Customer Mobile</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-white uppercase">
                                Review</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-white uppercase">
                                Comment Status</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-white uppercase">
                                Comment Submitted At</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-white uppercase">
                                Actions</th>

                        </thead>
                        <tbody *ngIf="videoList?.comments;else noData">
                            <tr *ngFor="let comment of videoList?.comments"
                                class=" dark:bg-gray-900  text-center even:bg-gray-200 odd:bg-white">
                                <td scope="row" class="py-4 px-6 text-sm font-medium text-black break-words text-left">
                                    {{comment?.video_id}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-black break-words text-left">
                                    {{comment?.cust_name}}
                                </td>
                                <td class="py-4 px-6 text-sm font-small text-black break-words text-left">
                                    {{comment?.mobileno}}<br>
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-black break-words text-left">
                                    {{comment?.review}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-black break-words text-left"
                                    [ngClass]="comment?.status==0 ? 'text-red-500' : 'text-green-500' ">
                                    {{comment?.status==0?"Not Approved": "Approved"}}
                                </td>

                                <td class="py-4 px-6 text-sm font-medium text-black break-words m-2 text-left">
                                    {{comment?.created_at| date:'longDate'}}
                                </td>
                                <td class=" text-sm font-medium text-black break-words m-2">
                                    <div class="flex text-white">
                                        <button class="p-1 bg-blue-400  hover:bg-blue-500 rounded m-1"
                                            (click)="openModal(template,comment?.id)">
                                            <mat-icon class="">edit</mat-icon>
                                        </button>
                                        <button class="p-1 bg-green-400  hover:bg-green-500 rounded m-1"
                                            *ngIf="comment?.status==0" (click)="StatusEdit(1,comment?.id)">
                                            <mat-icon>thumb_up</mat-icon>
                                        </button>
                                        <button class="p-1 bg-yellow-400  hover:bg-yellow-500 rounded m-1"
                                            *ngIf="comment?.status==1" (click)="StatusEdit(0,comment?.id)">
                                            <mat-icon>remove_circle</mat-icon>
                                        </button>
                                        <button class="p-1 bg-red-400  hover:bg-red-500 rounded m-1"
                                            (click)="StatusEdit(0,comment?.id,1)">
                                            <mat-icon>delete_outline</mat-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="flex justify-between p-2">
    <div>
        <mat-paginator [length]="videoList?.totalPage" [pageSize]="20" (page)="onPaginateChange($event)">
        </mat-paginator>

    </div>
</div>


<ng-template #template let-c="close">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Comments</h4>
        <!-- <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button> -->
    </div>
    <div class="modal-body">
        <form [formGroup]="commentForm" (ngSubmit)="updateReview()">
            <div class="relative z-0 my-6 w-full">
                <div class="relative z-0 my-6 w-full group">
                    <textarea name="floating_phone" id="floating_phone"
                        class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" " formControlName="review"></textarea>
                    <label for="floating_phone"
                        class="absolute text-sm text-gray-300 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Customer's
                        Review
                    </label>
                </div>
            </div> <button type="submit"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 float-right">Submit</button>
        </form>
    </div>

</ng-template>

<ng-template #noData>
    <tbody>
        <tr>
            <td colspan="12" class="text-center" style="font-size: 14px">
                No comments available
            </td>
        </tr>
    </tbody>
</ng-template>