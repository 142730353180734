<div>
    <h1>
        Manage Recharge Shortcuts:
    </h1>
</div>
<div class="flex justify-end m-5">
    <button (click)="openModal(template,null)"
        class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">Add
        new</button>
</div>
<div class="flex flex-col">
    <div class="overflow-x-auto shadow-md sm:rounded-lg">
        <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden ">
                <table class="min-w-full divide-y divide-gray-200 table-auto ">
                    <thead class="bg-gray-100 ">
                        <tr class="">
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Amount</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Status</th>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Action</th>
                        </tr>
                    </thead>
                    <tbody class=" bg-white divide-y divide-gray-200 ">
                        <tr *ngFor="let rechargeData of rechargeList" class="hover:bg-gray-100 cursor-pointer">
                            <td class="py-4 px-6 text-sm text-center font-medium text-gray-500 break-words">
                                {{rechargeData?.amount}}</td>
                            <td class="py-4 px-6 text-sm font-medium text-center text-gray-500 whitespace-nowrap">
                                {{rechargeData?.status==1?"Active":"InActive"}}</td>
                            <td class="py-4 px-6 text-sm font-medium  text-gray-500 whitespace-nowrap text-right">
                                <div class="flex text-white justify-center">
                                    <button class="p-1 bg-blue-400  hover:bg-blue-500 rounded m-1"
                                        (click)="openModal(template,rechargeData?.id)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button class="p-1 bg-red-400  hover:bg-red-500 rounded m-1"
                                        *ngIf="getPermisson('delete')" (click)="deleteRecharge(rechargeData?.id)">
                                        <mat-icon>delete_outline</mat-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <ng-template #noData>
                        <tbody>
                            <tr>
                                <td colspan="12" class="text-center" style="font-size: 14px">
                                    No data available
                                </td>
                            </tr>
                        </tbody>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-header flex justify-between p-0 m-0">
        <div class="float-right">
            <button (click)="closeModal()"><mat-icon>close</mat-icon></button>
        </div>
    </div>
    <div class="modal-content">
        <div class="p-5">
            <form [formGroup]="rechargeData" (submit)="onSubmit()" novalidate>
                <div class="flex items-center gap-3 w-full relative border-b-2 border-gray-300">
                    <div class="grid grid-cols-1 items-center gap-3 w-full">
                        <div class="col-span-1 relative z-0 mb-1 w-full">
                            <input type="number" name="amount"
                                class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" " formControlName="amount" />
                            <label for="amount"
                                class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Amount</label>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 items-center gap-3 w-full">
                        <div class="col-span-1 relative mb-1 w-full">
                            <div class="md:col-span-2  col-span-12">
                                <ng-select [items]="statusOptions" placeholder="Status" bindLabel="name"
                                    bindValue="value" formControlName="status" class="w-full">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center gap-3 w-full relative mt-5">
                    <div class="grid grid-cols-1 items-center gap-3 w-full">
                        <div class="col-span-1 relative z-0 mb-1 w-full">
                            <input type="number" name="firstUserdis"
                                class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" " formControlName="firstUserdis" />
                            <label for="firstUserdis"
                                class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Enter First Customer Discount</label>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <input checked id="checked-checkbox" type="checkbox" formControlName="isFirstActive"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="checked-checkbox"
                            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Active</label>
                    </div>
                </div>
                <div class="flex items-center gap-3 w-full relative mt-5">
                    <div class="grid grid-cols-1 items-center gap-3 w-full">
                        <div class="col-span-1 relative z-0 mb-1 w-full">
                            <input type="number" name="secondUserdis"
                                class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" " formControlName="secondUserdis" />
                            <label for="secondUserdis"
                                class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Enter Second Customer Discount</label>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <input checked id="checked-checkbox" type="checkbox" value="" formControlName="isSecondActive"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="checked-checkbox"
                            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Active</label>
                    </div>
                </div>
                <div class="flex items-center gap-3 w-full relative mt-5">
                    <div class="grid grid-cols-1 items-center gap-3 w-full">
                        <div class="col-span-1 relative z-0 mb-1 w-full">
                            <input type="number" name="thirdUserdis"
                                class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" " formControlName="thirdUserdis" />
                            <label for="thirdUserdis"
                                class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Enter Third Customer Discount</label>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <input checked id="checked-checkbox" type="checkbox" value="" formControlName="isThirdActive"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="checked-checkbox"
                            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Active</label>
                    </div>
                </div>
                <div class="flex items-center gap-3 w-full relative mt-5">
                    <div class="grid grid-cols-1 items-center gap-3 w-full">
                        <div class="col-span-1 relative z-0 mb-1 w-full">
                            <input type="number" name="allUserdis"
                                class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" " formControlName="allUserdis" />
                            <label for="allUserdis"
                                class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Enter All Customer Discount</label>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <input checked id="checked-checkbox" type="checkbox" value="" formControlName="isAllActive"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="checked-checkbox"
                            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Active</label>
                    </div>
                </div>
                <div class="float-right p-3">
                    <button type="submit" data-te-ripple-init data-te-ripple-color="light" *ngIf="getPermisson('write')"
                        class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>