import { Component } from '@angular/core';

@Component({
  selector: 'app-tarrot-cards',
  templateUrl: './tarrot-cards.component.html',
  styleUrls: ['./tarrot-cards.component.css']
})
export class TarrotCardsComponent {
  status: any
}
