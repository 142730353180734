<!-- <div class="flex justify-between items-center mb-5"> -->
<div class="flex justify-center mt-2 w-full">
  <div class="grid grid-cols-4 items-center gap-3 w-full">
    <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
      <h1>Manage Service:</h1>
    </div>
    <div class="md:col-span-1 col-span-12">
      <ng-select
        class=""
        [items]="broadcastType"
        placeholder="Broadcast Type"
        bindLabel="name"
        bindValue="value"
        [(ngModel)]="filter"
        class="w-full"
        (change)="getServiceList()"
      >
      </ng-select>
    </div>
    <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
      <input
        type="text"
        class="border-gray-200 w-full py-1 px-2 border-2 rounded-lg"
        placeholder="Search ..."
        (input)="searchProduct($event)"
      />
    </div>
    <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
      <div class="w-full">
        <button
          (click)="openModal(template)"
          class="float-right inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
        >
          Add New Service
        </button>
      </div>
    </div>
  </div>
</div>
<div class="flex flex-col">
  <div class="overflow-x-auto shadow-md sm:rounded-lg">
    <div class="inline-block min-w-full align-middle">
      <div class="overflow-hidden">
        <table class="min-w-full divide-y divide-gray-200 table-fixed">
          <thead class="bg-gray-100">
            <tr>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Image
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Service Name
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Category Name
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Broadcast Type
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                GST(%)
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Status
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Banners
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody
            *ngIf="serviceList?.length !== 0; else noData"
            class="bg-white divide-y divide-gray-200"
          >
            <tr
              *ngFor="let service of serviceList"
              class="hover:bg-gray-100 cursor-pointer"
            >
              <td class="text-sm font-medium text-gray-500 break-words">
                <img
                  [src]="service?.image1"
                  [alt]="service?.image1"
                  class="w-[100px] h-[100px]"
                />
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{ service?.serviceName }}
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{ service?.categoryName }}
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{ service?.broadcastType == "0" ? "Single" : "Multiple" }}
              </td>

              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{ service?.gst }}%
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{ service?.status == "0" ? "Active" : "De-Active" }}
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              ></td>

              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                <div class="flex text-white">
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    <mat-icon class="text-black">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button (click)="getProductById(service)" mat-menu-item>
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>
                    <button *ngIf="service?.broadcastType == '1'" routerLink="../manage-package-page"
                    [queryParams]="{
                      serviceId: service?.id,
                      name: service?.serviceName,
                      type: service?.broadcastType
                    }"  mat-menu-item>
                      <mat-icon>add</mat-icon>
                      <span>Add Packages</span>
                    </button>
                    <button *ngIf="service?.broadcastType == '1'" routerLink="../manage-landing-page"
                    [queryParams]="{
                      serviceId: service?.id,
                      name: service?.serviceName,
                      type: service?.broadcastType
                    }"  mat-menu-item>
                      <mat-icon>add</mat-icon>
                      <span>Add Landing Page</span>
                    </button>
                  </mat-menu>
                  <button
                    class="p-1 bg-red-400 hover:bg-red-500 rounded m-1"
                    (click)="isDelete(service?.id)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>

                  <button
                    class="p-1 bg-gray-400 hover:bg-gray-500 rounded m-1"
                    routerLink="../manage-service-astro-relation"
                    [queryParams]="{
                      serviceId: service?.id,
                      name: service?.serviceName,
                      type: service?.broadcastType
                    }"
                  >
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <ng-template #noData>
            <tbody>
              <tr>
                <td colspan="12" class="text-center" style="font-size: 14px">
                  No data available
                </td>
              </tr>
            </tbody>
          </ng-template>
        </table>
      </div>
    </div>
  </div>
</div>
<mat-paginator
[length]="paginationData?.totalPage"
[pageSize]="1"
hidePageSize="true"
(page)="pagination($event)"
>
</mat-paginator>

<ng-template #template>
  <div class="modal-header flex justify-between p-0 m-0">
    <div>
      <p class="font-bold text-lg">Add Service</p>
    </div>
    <div class="float-right">
      <button (click)="closeModal('Close')"><mat-icon>close</mat-icon></button>
    </div>
  </div>
  <div class="modal-content">
    <div class="p-5">
      <form
        [formGroup]="addService"
        (submit)="isEdit ? updateProduct() : submitProduct()"
      >
        <div class="flex justify-center mt-5 w-full">
          <div class="grid grid-cols-4 items-center gap-3 w-full">
            <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
              <input
                type="text"
                name="category_name"
                class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                formControlName="serviceName"
              />
              <label
                for="category_name"
                class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Service Name
              </label>
              <small
                class="text-danger"
                *ngIf="
                  addService.controls['serviceName'].invalid &&
                  (isFormSubmitted ||
                    addService.controls['serviceName'].touched)
                "
              >
                Required</small
              >
            </div>
            <div class="md:col-span-1 col-span-12">
              <ng-select
                class=""
                [items]="status"
                placeholder="Status"
                bindLabel="name"
                bindValue="value"
                formControlName="status"
                class="w-full"
              >
              </ng-select>
            </div>
            <div class="md:col-span-1 col-span-12">
              <ng-select
                class=""
                [items]="categoryList"
                placeholder="Product Category"
                bindLabel="CatName"
                bindValue="CatId"
                formControlName="categoryname"
                class="w-full"
              >
              </ng-select>
              <small
                class="text-danger"
                *ngIf="
                  addService.controls['categoryname'].invalid &&
                  (isFormSubmitted ||
                    addService.controls['categoryname'].touched)
                "
              >
                Required</small
              >
            </div>

            <div class="md:col-span-1 col-span-12">
              <ng-select
                class=""
                [items]="broadcastType"
                placeholder="Broadcast Type"
                bindLabel="name"
                bindValue="value"
                formControlName="broadcastType"
                class="w-full"
              >
              </ng-select>
            </div>

            <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
              <input
                type="text"
                name="category_name"
                class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                formControlName="gst"
              />
              <label
                for="category_name"
                class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                GST(%)
              </label>
              <small
                class="text-danger"
                *ngIf="
                  addService.controls['gst'].invalid &&
                  (isFormSubmitted || addService.controls['gst'].touched)
                "
              >
                Required</small
              >
            </div>
          </div>
        </div>

        <div class="flex justify-center mt-5 w-full">
          <div class="grid grid-cols-2 items-center gap-3 w-full">
            <div class="md:col-span-1 col-span-12 relative z-0 mb-6">
              <label
                for="formFile"
                class="mb-2 inline-block text-neutral-700 dark:text-neutral-200"
              >
                Main Image (Size:400px X 400px)</label
              >
              <input
                class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                type="file"
                id="formFile"
                (change)="fileupload($event, 1)"
                accept=".webp"
              />
            </div>
            <div class="md:col-span-1 col-span-12 relative z-0 mb-6 w-full">
              <label
                for="formFile"
                class="mb-2 inline-block text-neutral-700 dark:text-neutral-200"
              >
                Image (Size:400px X 400px)</label
              >
              <input
                class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                type="file"
                id="formFile"
                (change)="fileupload($event, 2)"
                accept=".webp"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
          <div class="grid grid-cols-2 items-center gap-3 w-full">
            <div class="md:col-span-1 col-span-12 relative z-0 mb-6">
              <label
                for="formFile"
                class="mb-2 inline-block text-neutral-700 dark:text-neutral-200"
              >
                Image (Size:400px X 400px)</label
              >
              <input
                class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                type="file"
                id="formFile"
                (change)="fileupload($event, 3)"
                accept=".webp"
              />
            </div>
            <div class="md:col-span-1 col-span-12 relative z-0 mb-6 w-full">
              <label
                for="formFile"
                class="mb-2 inline-block text-neutral-700 dark:text-neutral-200"
              >
                Image (Size:400px X 400px)</label
              >
              <input
                class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                type="file"
                id="formFile"
                (change)="fileupload($event, 4)"
                accept=".webp"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
          <div class="grid grid-cols-1 items-center gap-3 w-full">
            <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
              <textarea
                type="text"
                name="category_name"
                class="block h-32 py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                cols="3"
                formControlName="desc"
              ></textarea>
              <label
                for="category_name"
                class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Product Main Description
              </label>
              <small
                class="text-danger"
                *ngIf="
                  addService.controls['desc'].invalid &&
                  (isFormSubmitted || addService.controls['desc'].touched)
                "
              >
                Required</small
              >
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
          <div class="grid grid-cols-1 items-center gap-3 w-full">
            <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
              <input
                type="text"
                name="category_name"
                class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                formControlName="question1"
              />
              <label
                for="category_name"
                class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Question 1
              </label>
            </div>
            <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
              <textarea
                type="text"
                name="category_name"
                class="block h-32 py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                cols="3"
                formControlName="desc1"
              ></textarea>
              <label
                for="category_name"
                class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Description 1
              </label>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
          <div class="grid grid-cols-1 items-center gap-3 w-full">
            <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
              <input
                type="text"
                name="category_name"
                class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                formControlName="question2"
              />
              <label
                for="category_name"
                class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Question 2
              </label>
            </div>
            <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
              <textarea
                type="text"
                name="category_name"
                class="block h-32 py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                cols="3"
                formControlName="desc2"
              ></textarea>
              <label
                for="category_name"
                class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Description 2
              </label>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
          <div class="grid grid-cols-1 items-center gap-3 w-full">
            <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
              <input
                type="text"
                name="category_name"
                class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                formControlName="question3"
              />
              <label
                for="category_name"
                class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Question 3
              </label>
            </div>
            <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
              <textarea
                type="text"
                name="category_name"
                class="block h-32 py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                cols="3"
                formControlName="desc3"
              ></textarea>
              <label
                for="category_name"
                class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Description 3
              </label>
            </div>
          </div>
        </div>

        <div class="flex justify-end w-full">
          <button
            type="submit"
            class="m-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {{ isEdit ? "Update" : "Upload" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
