<div class="flex justify-center mt-5 w-full">
    <div class="grid grid-cols-3 items-center gap-3 w-full">
        <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">

        </div>
        <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
            <p class="text-center font-extrabold text-2xl">Manage Prizes</p>
        </div>
        <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
            <div class="w-full">
                <button (click)="openModal(template)"
                    class="float-right inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                    Add Prize
                </button>
            </div>
        </div>
    </div>
</div>

<div [formGroup]="orderForm">
    <div formArrayName="items" *ngFor="let item of prizesList;let i = index; ">
        <div [formGroupName]="i">
            <div class="flex relative justify-between mb-5 p-2 w-full border">
                <div class="grid grid-cols-1 items-center m-1 w-full">
                    <div class="group md:col-span-12 col-span-12">
                        <div class="mb-4">
                            <label class="block text-gray-700 font-bold mb-2" for="image">Image Preview</label>
                            <div class="w-full h-32 border border-gray-300 rounded-md overflow-hidden">
                                <img id="image-preview" class="w-full h-full object-cover" [src]="item.pImage"
                                    alt="Image Preview">
                            </div>
                        </div>
                        <div class="grid grid-cols-1 items-center gap-3 w-full md:col-span-12">
                            <div class="mb-4">
                                <label class="w-full block text-gray-700 font-bold mb-2" for="image">Upload
                                    Image</label>
                                <input type="file" [id]="i" name="uploadOfferImg" accept="image/*"
                                    class="py-2 px-3 border content['ok'] border-gray-300 rounded-md w-full"
                                    (change)="fileupload($event,i)">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-1 items-center m-1 gap-4 w-full">
                    <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                        <input type="text" name="price"
                            class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " formControlName="price" [ngModel]="item.price" />
                        <label for="price"
                            class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Price
                        </label>
                    </div>
                    <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                        <input type="text" name="giftName"
                            class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " formControlName="giftName" [ngModel]="item.giftName" />
                        <label for="giftName"
                            class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Name
                        </label>
                    </div>
                    <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                        <input type="text" name="astroCommission"
                            class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " maxlength="6" formControlName="astroCommission"
                            [value]="item.astroCommission" />
                        <label for="astroCommission"
                            class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Astrologer Percentage
                        </label>
                    </div>
                </div>
                <div class="grid grid-cols-1 items-center m-1 p-1 gap-4 w-full">
                    <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                        <input type="text" name="magCommission"
                            class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " formControlName="magCommission" [value]="item.magCommission" />
                        <label for="magCommission"
                            class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Own Percentage
                        </label>
                    </div>
                    <div class="md:col-span-1 col-span-12 mt-6">
                        <ng-select class="" [items]="option" formControlName="status" placeholder="Status"
                            bindLabel="name" bindValue="value" class="w-full">
                        </ng-select>
                    </div>
                    <div class="md:col-span-1 flex justify-center col-span-12 my-6">
                        <button type="submit" (click)="updateData(i)"
                            class="mx-1 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Update
                        </button>
                        <button type="submit" (click)="deleteData(i)"
                            class="mx-1 w-full text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                            Delete
                        </button>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="flex justify-between">
        <!-- <div
              class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              <button (click)="addItem()">Add Permission</button>
          </div>
          <div
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              <button type="submit" (click)="submit()">Submit</button>
          </div> -->
    </div>
</div>

<ng-template #template>
    <div class="modal-header flex justify-between p-0 m-0">
        <div>
            <p class="font-bold text-lg">Add Prizes</p>
        </div>
        <div class="float-right">
            <button (click)="closeModal()"><mat-icon>close</mat-icon></button>
        </div>
    </div>
    <form [formGroup]="prize" (submit)="addPrize()">
        <div class="modal-content">
            <div class="p-5">
                <div class="relative z-0 mb-4  w-full">
                    <input type="file" name="soulMates" accept="image/*"
                        class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        (change)="fileupload($event)" placeholder="" />
                    <label for="soulMates"
                        class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Gift
                        Image
                    </label>
                </div>
                <div class="col-span-1 relative z-0 mb-4 w-full">
                    <input type="number" name="Price"
                        class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" " formControlName="price" />
                    <label for="Price"
                        class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Price</label>
                </div>
                <div class="col-span-1 relative z-0 mb-4 w-full">
                    <input type="text" name="giftName"
                        class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" " formControlName="giftName" />
                    <label for="giftName"
                        class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Name</label>
                </div>
                <div class="col-span-1 relative z-0 mb-4 w-full">
                    <input type="number" name="astroCommission"
                        class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" " formControlName="astroCommission" />
                    <label for="astroCommission"
                        class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Astrologer Percentage</label>
                </div>
                <div class="col-span-1 relative z-0 mb-4 w-full">
                    <input type="number" name="magCommission"
                        class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" " formControlName="magCommission" />
                    <label for="magCommission"
                        class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Own Percentage</label>
                </div>
                <div class="col-span-1 relative z-0 mb-4 w-full">
                    <ng-select [items]="option" placeholder="Status" bindLabel="name" bindValue="value"
                        formControlName="status" class="w-full">
                    </ng-select>
                </div>

                <div class="flex justify-end w-full">
                    <button type="submit"
                        class="m-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </form>
</ng-template>