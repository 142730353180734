<!-- <h1 class="mb-5 mt-10 text-blue-500">Customer Activity</h1> -->
<div class="mx-auto mt-5">
  <form [formGroup]="acitiviyData">
    <div class="flex justify-center w-full">
      <div class="grid grid-cols-2 items-center gap-3 w-full">
        <div class="md:col-span-1 col-span-12 mb-5">
          <!-- <ng-select [items]="option" placeholder="Sort By" bindLabel="name" bindValue="value" formControlName="status"
            class="w-full" (change)="getCallReport()">
          </ng-select> -->
          <p class="text-xl font-bold underline tracking-widest">Customer Activity:</p>
        </div>
        <div class="md:col-auto col-span-12">
          <input type="text" class="border-2 py-1 px-2 w-full" ngxDaterangepickerMd [showCustomRangeLabel]="true"
            [(ngModel)]="selected" [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true"
            [isInvalidDate]="isInvalidDate" formControlName="StartDate" [showClearButton]="true"
            placeholder="Select date range " (change)="choosedDate($event)" />
        </div>
        <!-- <div class="md:col-span-1 col-span-12"> -->
          <!-- <ng-select [items]="filter" placeholder="Filter By" bindLabel="name" bindValue="value"
            formControlName="sfilter" class="w-full" (change)="getCallReport()">
          </ng-select> -->
        <!-- </div> -->
        <!-- <div class="md:col-auto col-span-12">
          <input type="text" class="border-gray-200 py-1 px-2 w-full border-2" placeholder="Search ..."
            (input)="search($event)" /> -->
        <!-- </div> -->
      </div>
    </div>
  </form>

  <div class="flex flex-col">
    <div class="overflow-x-auto shadow-md sm:rounded-lg">
      <div class="inline-block min-w-full align-middle">
        <div class="overflow-hidden">
          <table class="min-w-full divide-y divide-gray-200 table-fixed">
            <thead class="bg-gray-100">
              <tr>
                <th scope="col" class="py-3 px-6 text-xs font-bold tracking-wider text-left text-gray-700 uppercase">
                  Action Type
                </th>
                <!-- <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                  Start Time
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                  End Time
                </th> -->
                <th scope="col" class="py-3 px-6 text-xs font-bold tracking-wider text-left text-gray-700 uppercase">
                  Description
                </th>
                
              </tr>
            </thead>
            <tbody *ngIf="activityList?.activityList?.length !== 0; else noData"
              class="bg-white divide-y divide-gray-200">
              <tr *ngFor="let item of activityList?.activityList" class="hover:bg-gray-100 cursor-pointer">
                <td 
                  class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                  {{ item?.action }}
                </td>
                <!-- <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                  {{ item?.startTime | date : "short" }}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                  {{ item?.endTime | date : "short" }}
                </td> -->
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                  <!-- {{ item?.completeInfo }} -->
                  <div *ngFor="let items of item?.completeInfo | keyvalue">
                     <b>{{items.key}}</b>:  <b>{{items.value}}</b>
                  </div>
                </td>
              </tr>
            </tbody>
            <ng-template #noData>
              <tbody>
                <tr>
                  <td colspan="12" class="text-center" style="font-size: 14px">
                    No data available
                  </td>
                </tr>
              </tbody>
            </ng-template>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


<mat-paginator [length]="activityList?.totalPage" [pageSize]="1" hidePageSize="true" onchange="getData()"
  (page)="onPaginateChange($event)">
</mat-paginator>


