import { Component } from '@angular/core';

@Component({
  selector: 'app-video-h',
  templateUrl: './video-h.component.html',
  styleUrls: ['./video-h.component.css']
})
export class VideoHComponent {

}
