import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin-api/admin-api.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { NotifierService } from 'src/app/shared/services/toaster.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-dakshina',
  templateUrl: './dakshina.component.html',
  styleUrls: ['./dakshina.component.css']
})
export class DakshinaComponent implements OnInit {
  dakshinaList: any;
  totalData: any;
  monthIndex: any;
  year: any;
  search: any;
  modelDate = new Date();
  totalPage: any;
  page = 1;
  excel = false;
  parseFloat = parseFloat;
  modelChanged: Subject<any> = new Subject<any>();

  constructor(
    private modalService: BsModalService,
    private _AdminService: AdminService,
    private _SpinnerService: SpinnerService,
    private _FormBuilder: FormBuilder,
    private _NotifierService: NotifierService
  ) { }

  ngOnInit(): void {
    this.modelChanged.pipe(debounceTime(300), distinctUntilChanged()).subscribe((val) => {
      this.search = val?.search;
      this.page = 1;
      this.getDakshina()
    });
    this.getDakshina();
  }

  getDakshina() {
    this._AdminService.showDakshina({
      monthIndex: this.monthIndex,
      year: this.year,
      search: this.search,
      page: this.page,
      excel: this.excel
    }).then((data) => {
      if (data?.status) {
        if (this.excel) {
          this.excel = false;
          return data?.data;
        }
        else {
          this.dakshinaList = data?.data?.detailDakshina;
          this.totalData = data?.data?.totalDaks;
          this.totalPage = data?.data?.totalPage;
          return;
        }
      }
      return;
    });
  }
  onOpenCalendar(container: any) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }
  getDate(date: any) {
    if (date) {
      // this.excelS3link = ""
      const selectedMonth = new Date(date);
      console.log(selectedMonth.getFullYear())
      this.monthIndex = selectedMonth.getMonth()
      this.year = selectedMonth.getFullYear();
      this.getDakshina();
    }
  }
  searchTxt(event: any) {
    // this.page = 1;
    this.modelChanged.next({ search: event.target.value })
    // this.getnewAstro()
  }
  pagination($: any) {
    this.page = $.pageIndex + 1;
    this.getDakshina();
  }
  exportexcel() {
    debugger
    this.excel = true;
    let fileName = 'DakshinaList.xlsx';
    this._AdminService.showDakshina({
      monthIndex: this.monthIndex,
      year: this.year,
      search: this.search,
      page: this.page,
      excel: this.excel
    }).then((data) => {
      this._SpinnerService.setState(true);
      console.log(data?.data?.detailDakshina)
      if (data?.status) {
        let exceldata: any = [];
        for (let datas of data?.data?.detailDakshina) {
          exceldata.push({
            AstroId: datas?.astroid,
            AstrologerName: datas?.astroName,
            FullName: datas?.astroName,
            AccountNo: datas?.AccountNo,
            IFSCCode: datas?.IFSCCode,
            AccountBranch: datas?.AccountBranch,
            AccountName: datas?.AccountName,
            AstroAmount: datas?.astroAmt,
          });
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exceldata);
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Dakshina Payment');
        /* save to file */
        XLSX.writeFile(wb, fileName);
        this._SpinnerService.setState(false);
      } else this._NotifierService.showError('Some Error Occurred!');
    });

  }

}
