<!-- <div class="flex justify-between items-center mb-5"> -->

  <div class="flex justify-center mt-5 w-full">
    <div class="grid grid-cols-3 items-center gap-3 w-full">
      <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6"><h1>Manage Products:</h1></div>
      <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
        <input type="text" class=" border-gray-200 w-full py-1 px-2 border-2 rounded-lg" placeholder="Search ..."
        (input)="searchProduct($event)">
      </div>
      <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
        <div class="w-full  ">
          <button
            (click)="openModal(template)"
            class="float-right inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            Add New Products
          </button>
        </div>
      </div>
      </div>
      </div>
<div class="flex flex-col">
  <div class="overflow-x-auto shadow-md sm:rounded-lg">
    <div class="inline-block min-w-full align-middle">
      <div class="overflow-hidden">
        <table class="min-w-full divide-y divide-gray-200 table-fixed">
          <thead class="bg-gray-100">
            <tr>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Image
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Product Name
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Category
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Product Type
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Product Purchase Price
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Profit on Product
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                GST(%)
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                GSTAmt
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                DiscAmt
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Display Price
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Status
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                EntryDate
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Stock
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Banners
              </th>
              <th
                scope="col"
                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody
            *ngIf="productList?.length !== 0; else noData"
            class="bg-white divide-y divide-gray-200"
          >
            <tr
              *ngFor="let products of productList"
              class="hover:bg-gray-100 cursor-pointer"
            >
              <td class="text-sm font-medium text-gray-500 break-words">
                <img
                  [src]="products?.newImage1"
                  [alt]="products?.newImage1"
                  class="w-[100px] h-[100px]"
                />
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{ products?.ProductName }}
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{ products?.CatName }}
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                <p>{{ products?.ProductType }}</p>
                <p>
                  {{
                    products?.productOrService == '1' ? "(product)" : "(service)"
                  }}
                </p>
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{ products?.Price }}/-
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{ products?.profitOnProduct }}/-
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{ products?.GSTPer }}%
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{
                  gstAmount(
                    products?.GSTPer,
                    products?.profitOnProduct,
                    products?.Price
                  )
                }}/-
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{ products?.DiscAmt }}/-
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{
                  totalAmount(
                    products?.GSTPer,
                    products?.profitOnProduct,
                    products?.Price,
                    products?.DiscAmt
                  )
                }}/-
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{ products?.Status == "0" ? "Active" : "De-Active" }}
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{ products?.EntryDate }}
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                {{ products?.Quantity }}
              </td>
              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              ></td>

              <td
                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap"
              >
                <div class="flex text-white">
                  <button (click)="getProductById(products?.ProductId)" class="p-1 bg-blue-400 hover:bg-blue-500 rounded m-1">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <!-- <button class="p-1 bg-red-400  hover:bg-red-500 rounded m-1"
                                        (click)="deleteBanner(banners?.BannerId)">
                                        <mat-icon>delete_outline</mat-icon>
                                    </button> -->
                </div>
              </td>
            </tr>
          </tbody>
          <ng-template #noData>
            <tbody>
              <tr>
                <td colspan="12" class="text-center" style="font-size: 14px">
                  No data available
                </td>
              </tr>
            </tbody>
          </ng-template>
        </table>
      </div>
    </div>
  </div>
</div>
<mat-paginator
  [length]="paginationData?.totalData"
  [pageSize]="20"
  hidePageSize="true"
  (page)="pagination($event)"
>
</mat-paginator>

<!-- <mat-paginator [length]="blogList?.totalBlogs" [pageSize]="1" hidePageSize="true" (page)="pagination($event)">
</mat-paginator> -->

<ng-template #template>
  <div class="modal-header flex justify-between p-0 m-0">
    <div>
      <p class="font-bold text-lg">Add Product</p>
    </div>
    <div class="float-right">
      <button (click)="closeModal('Close')"><mat-icon>close</mat-icon></button>
    </div>
  </div>
  <div class="modal-content">
    <div class="p-5">
      <form [formGroup]="addProduct" (submit)="isEdit?updateProduct():submitProduct()">
        <div class="flex justify-center mt-5 w-full">
          <div class="grid grid-cols-4 items-center gap-3 w-full">
            <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
              <input
                type="text"
                name="category_name"
                class="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                formControlName="productName"
              />
              <label
                for="category_name"
                class="absolute  text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3  z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Product Name
              </label>
              <small
                class="text-danger"
                *ngIf="
                  addProduct.controls['productName'].invalid &&
                  (isFormSubmitted ||
                    addProduct.controls['productName'].touched)
                "
              >
                Required</small
              >
            </div>
            <div class="md:col-span-1  col-span-12 ">
                <ng-select class="" [items]="status"  placeholder="Status" bindLabel="name" bindValue="value"
                    formControlName="status" class="w-full" >
                </ng-select>
                
            </div>
            <div class="md:col-span-1  col-span-12 ">
                <ng-select class="" [items]="categoryList"  placeholder="Product Category" bindLabel="CatName" bindValue="CatId"
                    formControlName="categoryname" class="w-full" >
                </ng-select>
                <small
                class="text-danger"
                *ngIf="
                  addProduct.controls['categoryname'].invalid &&
                  (isFormSubmitted ||
                    addProduct.controls['categoryname'].touched)
                "
              >
                Required</small
              >
            </div>
            <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  type="text"
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="quantity"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3  z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Quantity
                </label>
                <small
                  class="text-danger"
                  *ngIf="
                    addProduct.controls['productName'].invalid &&
                    (isFormSubmitted ||
                      addProduct.controls['productName'].touched)
                  "
                >
                  Required</small
                >
              </div>
          </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
            <div class="grid grid-cols-4 items-center gap-3 w-full">
              <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  type="text"
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="purchasePrice"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                Product Purchase Price
                </label>
                <small
                  class="text-danger"
                  *ngIf="
                    addProduct.controls['purchasePrice'].invalid &&
                    (isFormSubmitted ||
                      addProduct.controls['purchasePrice'].touched)
                  "
                >
                  Required</small
                >
              </div>
              <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  type="text"
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="ownProfit"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                Profit on Product(own margin)
                </label>
              </div>
              <div class="md:col-span-1  col-span-12 ">
                  <ng-select class="" [items]="productType"  placeholder="Product/Service" bindLabel="name" bindValue="value"
                      formControlName="productType" class="w-full" >
                  </ng-select>
                  
              </div>
              <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  type="text"
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="gst"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                GST(%)
                </label>
                <small
                  class="text-danger"
                  *ngIf="
                    addProduct.controls['gst'].invalid &&
                    (isFormSubmitted ||
                      addProduct.controls['gst'].touched)
                  "
                >
                  Required</small
                >
              </div>

              
            </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
            <div class="grid grid-cols-4 items-center gap-3 w-full">
              
              <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  type="text"
                  readonly
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="gstAmt"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                GST Amt
                </label>
              </div>

              <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  type="text"
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="discAmt"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                Disc Amt
                </label>

              </div>

              <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  readonly
                  type="text"
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="totalAmount"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                Total Price (gst+margin+price)
                </label>
              </div>
              <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  readonly
                  type="text"
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="displayAmount"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                Display Price
                </label>
              </div>
              
            </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
            <div class="grid grid-cols-4 items-center gap-3 w-full">
              <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  type="text"
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="hsncode"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                HSN CODE
                </label>
              </div>
              <div class="md:col-span-1  col-span-12 ">
                  <ng-select class="" [items]="productForType"  placeholder="Product Type" bindLabel="name" bindValue="value"
                      formControlName="productOriginType" class="w-full" >
                  </ng-select>
                  
              </div>
              <div class="md:col-span-1  col-span-12 " *ngIf="addProduct?.get('productOriginType')?.value!=='inhouse'" >
                <ng-select class="" [items]="astroList"  placeholder="Astrologer" bindLabel="fullname" bindValue="astroid"
                    formControlName="astrologer" class="w-full" >
                </ng-select>
            </div>
            </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
            <div class="grid grid-cols-4 items-center gap-3 w-full">
              
              <ng-container *ngIf="addProduct?.get('productOriginType')?.value=='inhouse'" >
              <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  type="text"
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="recomPerc"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                Recommendation(%)
                </label>
              </div>

              <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  type="text"
                  readonly
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="recomAmt"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                Recommendation Amt
                </label>

              </div>
            </ng-container>
          <ng-container *ngIf="addProduct?.get('productOriginType')?.value!=='inhouse'">
              <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  type="text"
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="astroPerc"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                Astrologer Profit(%)
                </label>
              </div>

              <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  type="text"
                  readonly
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="astroAmt"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                Astrologer Profit Amt
                </label>

              </div>
            </ng-container>

              <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  readonly
                  type="text"
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="magProfitPer"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                MyAstroguruji Profit(%)
                </label>
              </div>
              <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                <input
                  readonly
                  type="text"
                  name="category_name"
                  class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  formControlName="magProfitAmt"
                />
                <label
                  for="category_name"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                MyAstroguruji Profit Amt
                </label>
              </div>
              
            </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
            <div class="grid grid-cols-2 items-center gap-3 w-full">
                <div class="md:col-span-1 col-span-12 relative z-0 mb-6 ">
                    <label for="formFile" class="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
                        Main Image (Size:400px X 400px)</label>
                    <input
                        class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                        type="file" id="formFile" (change)="fileupload($event,1)" accept=".webp" />
                </div>
                <div class="md:col-span-1 col-span-12 relative z-0 mb-6 w-full">
                    <label for="formFile" class="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
                        Image (Size:400px X 400px)</label>
                    <input
                        class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                        type="file" id="formFile" (change)="fileupload($event,2)" accept=".webp" />
                </div>
                
            </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
            <div class="grid grid-cols-2 items-center gap-3 w-full">
                <div class="md:col-span-1 col-span-12 relative z-0 mb-6 ">
                    <label for="formFile" class="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
                        Image (Size:400px X 400px)</label>
                    <input
                        class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                        type="file" id="formFile" (change)="fileupload($event,3)" accept=".webp" />
                </div>
                <div class="md:col-span-1 col-span-12 relative z-0 mb-6 w-full">
                    <label for="formFile" class="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
                        Image (Size:400px X 400px)</label>
                    <input
                        class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                        type="file" id="formFile" (change)="fileupload($event,4)" accept=".webp" />
                </div>
                
            </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
            <div class="grid grid-cols-1 items-center gap-3 w-full">
                <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                    <textarea
                      type="text"
                      name="category_name"
                      class="block h-32 py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      cols="3"
                      formControlName="desc"
                    ></textarea>
                    <label
                      for="category_name"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                    Product Main Description
                    </label>
                    <small
                    class="text-danger"
                    *ngIf="
                        addProduct.controls['desc'].invalid &&
                        (isFormSubmitted ||
                        addProduct.controls['desc'].touched)
                    "
                    >
                    Required</small
                    >
                  </div>
            </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
            <div class="grid grid-cols-1 items-center gap-3 w-full">
                <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                    <input
                      type="text"
                      name="category_name"
                      class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      formControlName="question1"
                    />
                    <label
                      for="category_name"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                    Question 1
                    </label>
                  </div>
                <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                    <textarea
                      type="text"
                      name="category_name"
                      class="block h-32 py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      cols="3"
                      formControlName="desc1"
                    ></textarea>
                    <label
                      for="category_name"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                    Description 1
                    </label>

                  </div>
            </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
            <div class="grid grid-cols-1 items-center gap-3 w-full">
                <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                    <input
                      type="text"
                      name="category_name"
                      class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      formControlName="question2"
                    />
                    <label
                      for="category_name"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                    Question 2
                    </label>
                  </div>
                <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                    <textarea
                      type="text"
                      name="category_name"
                      class="block h-32 py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      cols="3"
                      formControlName="desc2"
                    ></textarea>
                    <label
                      for="category_name"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                    Description 2
                    </label>

                  </div>
            </div>
        </div>
        <div class="flex justify-center mt-5 w-full">
            <div class="grid grid-cols-1 items-center gap-3 w-full">
                <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                    <input
                      type="text"
                      name="category_name"
                      class="block py-2.5 px-2  w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      formControlName="question3"
                    />
                    <label
                      for="category_name"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                    Question 3
                    </label>
                  </div>
                <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
                    <textarea
                      type="text"
                      name="category_name"
                      class="block h-32 py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      cols="3"
                      formControlName="desc3"
                    ></textarea>
                    <label
                      for="category_name"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3   z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                    Description 3
                    </label>

                  </div>
            </div>
        </div>
        
        <div class="flex justify-end w-full">
          <button
            type="submit"
            class="m-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {{isEdit?'Update':'Upload'}}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
