<div class="flex justify-between my-5">
    <h1>
        Manage Astro Videos:
    </h1>
    <div>
        <button (click)="openModal(null,template)"
            class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">Add
            new Link</button>
    </div>
</div>

<div class="flex flex-col">
    <div class="overflow-x-auto shadow-md sm:rounded-lg">
        <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden ">
                <table class="min-w-full divide-y divide-gray-200 table-fixed ">
                    <thead class="bg-gray-100 ">
                        <tr>
                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                Video Title</th>

                            <th scope="col"
                                class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase float-right">
                            </th>
                        </tr>
                    </thead>
                    <tbody class=" bg-white divide-y divide-gray-200 " *ngFor="let video of videoList?.data">
                        <tr class="hover:bg-gray-100 cursor-pointer">
                            <td class="py-4 px-6 text-sm font-medium text-gray-500 break-words">
                                {{video?.title}}</td>
                            <td
                                class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 float-right">
                                <div class="flex text-white w-full">
                                    <button class="p-3 bg-emerald-400  hover:bg-emerald-500 rounded m-1 relative"
                                        [routerLink]="prof?'../../../astro_pv_comments':'../astro_pv_comments'" [queryParams]="{vid:video?.id}">
                                        <span class="mb-5">View Comments
                                        </span>
                                        <div *ngIf="video?.pendingcmt>0"
                                            class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                                            {{video?.pendingcmt}}</div>
                                    </button>
                                    <button class="p-1 bg-blue-400  hover:bg-blue-500 rounded m-1"
                                        (click)="openModal(video?.id,template)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button class="p-1 bg-red-400  hover:bg-red-500 rounded m-1"
                                        *ngIf="getPermisson('delete')">
                                        <mat-icon>delete_outline</mat-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <ng-template #noData>
                        <tbody>
                            <tr>
                                <td colspan="12" class="text-center" style="font-size: 14px">
                                    No data available
                                </td>
                            </tr>
                        </tbody>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>
<mat-paginator [length]="videoList?.totalPage" [pageSize]="20" hidePageSize="true" (page)="onPaginateChange($event)">
</mat-paginator>
<ng-template #template>
    <div class="modal-header flex justify-between p-0 m-0 ">
        <div>
            <button (click)="closeModal()" class="float-right"><mat-icon>close</mat-icon></button>
        </div>
    </div>
    <div class="modal-content">
        <div class="p-5">
            <form [formGroup]="videoData" (submit)="onSubmit()" novalidate>
                <div class="grid grid-cols-1 items-center gap-3 w-full">

                    <div class="relative z-0 mb-6 w-full">
                        <input type="text" name="title"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " formControlName="title" />
                        <label for="title"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Title
                        </label>
                    </div>

                    <div class="relative z-0 mb-6 w-full">
                        <label for="formFile" class="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
                            Thumbnail</label>
                        <input
                            class="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                            type="file" id="formFile" (change)="fileupload($event)" accept=".webp" />
                    </div>

                    <div class="relative z-0 mb-6 w-full">
                        <input type="text" name="link"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " formControlName="link" />
                        <label for="link"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Youtube
                            URL
                        </label>
                    </div>

                    <div class="relative z-0 mb-6 w-full">
                        <input type="text" name="description"
                            class="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " formControlName="description" />
                        <label for="description"
                            class="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Description

                        </label>
                    </div>
                </div>

                <div class="float-right p-3">
                    <button type="submit" data-te-ripple-init data-te-ripple-color="light" *ngIf="getPermisson('write')"
                        class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>