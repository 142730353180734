<div class="flex justify-center mt-5 w-full">
  <div class="grid grid-cols-3 items-center gap-3 w-full">
    <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
      <h1>Manage Common Service Order:</h1>
    </div>
    <!-- <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
      <div class="w-full">
        <ng-select
          [items]="PaymentStatusFilter"
          placeholder="Payment Status"
          bindLabel="name"
          bindValue="value"
          class="w-full"
          [(ngModel)]="statusFilter"
          (change)="getServiceOrderList()"
        >
        </ng-select>
      </div>
    </div> -->
    <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
      <div class="w-full">
        <ng-select [items]="option" placeholder="Sort By" bindLabel="name" bindValue="value" class="w-full"
          (change)="handleFilter($event)">
        </ng-select>
      </div>
    </div>
    <div class="md:col-span-1 col-span-12 -bottom-2 relative z-0 mb-6">
      <input type="text" class="border-gray-200 w-full py-1 px-2 border-2 rounded-lg" placeholder="Search ..."
        (input)="searchProduct($event)" />
    </div>
  </div>
</div>
<div class="flex flex-col">
  <div class="overflow-x-auto shadow-md sm:rounded-lg">
    <div class="inline-block min-w-full align-middle">
      <div class="overflow-hidden">
        <table class="min-w-full divide-y divide-gray-200 table-fixed">
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                Service ID
              </th>
              <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                Scheduled On
              </th>
              <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                Service Name
              </th>
              <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                Category Name
              </th>
              <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                Astrologer Name
              </th>
              <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                Seats
              </th>
              <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                Status
              </th>
              <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                Service Status
              </th>

              <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                Purchase Details
              </th>

              <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                Recordings
              </th>
            </tr>
          </thead>
          <tbody *ngIf="serviceList?.length !== 0; else noData" class="bg-white divide-y divide-gray-200">
            <tr *ngFor="let service of serviceList" class="hover:bg-gray-100 cursor-pointer">
              <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                {{ service?.serviceDetails?.astroServiceId }}
              </td>
              <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                {{
                service?.serviceDetails?.selectedtime
                | date : "dd-MM-YYYY hh:mm a"
                }}
              </td>
              <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                {{ service?.serviceName }}
              </td>
              <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                {{ service?.categoryName }}
              </td>
              <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                {{ service?.astroName }}
              </td>
              <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                {{ service?.booked }}/{{ service?.seats }}
              </td>
              <td class="py-4 px-6 text-sm font-medium whitespace-nowrap" [ngClass]="
                    service?.OrderStatus == 'Success'
                      ? 'text-success-600'
                      : 'text-red-600'
                  ">
                {{ service?.OrderStatus }}
              </td>
              <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                <ng-container *ngIf="service?.OrderStatus == 'Success'">
                  <span *ngIf="service?.serviceDetails?.serviceStatus == '1'"
                    class="px-3 py-2 rounded-lg bg-blue-700 text-white">
                    Service Booked By User,Form Pending
                  </span>
                  <span *ngIf="service?.serviceDetails?.serviceStatus == '2'"
                    class="px-3 py-2 rounded-lg bg-blue-700 text-white">
                    Form Updated By User,Time Pending
                  </span>
                  <span *ngIf="service?.serviceDetails?.serviceStatus == '3'"
                    class="px-3 py-2 rounded-lg bg-blue-700 text-white">
                    Time Updated By Astrologer,Time Selection Pending
                  </span>
                  <span *ngIf="service?.serviceDetails?.serviceStatus == '4'"
                    class="px-3 py-2 rounded-lg bg-blue-700 text-white">
                    Time Selected By User,Puja Pending
                  </span>
                  <span *ngIf="service?.serviceDetails?.serviceStatus == '5'"
                    class="px-3 py-2 rounded-lg bg-yellow-700 text-white">
                    Puja Started
                  </span>
                  <span *ngIf="service?.serviceDetails?.serviceStatus == '6'"
                    class="px-3 py-2 rounded-lg bg-green-700 text-white">
                    Puja Completed
                  </span>
                </ng-container>
              </td>

              <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                <button
                  (click)="getUserList(template,service?.serviceDetails?.astroServiceId,{name:service?.serviceName,time:service?.serviceDetails?.selectedtime})"
                  class="px-3 py-2 rounded-lg bg-blue-700 text-white">
                  View User Details
                </button>
              </td>
              <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                <a [href]="service?.serviceDetails?.recordinURL" target="_blank"
                  *ngIf="service?.serviceDetails?.recordinURL" class="px-3 py-2 rounded-lg bg-blue-700 text-white">
                  View Recordings
                </a>
                <span *ngIf="!service?.serviceDetails?.recordinURL" class="">
                  No Recordings
                </span>
              </td>
            </tr>
          </tbody>
          <ng-template #noData>
            <tbody>
              <tr>
                <td colspan="12" class="text-center" style="font-size: 14px">
                  No data available
                </td>
              </tr>
            </tbody>
          </ng-template>
        </table>
      </div>
    </div>
  </div>
</div>
<mat-paginator [length]="paginationData?.totalPage" [pageSize]="1" hidePageSize="true" (page)="pagination($event)">
</mat-paginator>

<ng-template #template>
  <div class="modal-header flex justify-between p-0 m-0">
    <div>
      <p class="font-bold text-lg">User Order Details</p>
    </div>
    <div class="float-right">
      <button (click)="closeModal('Close')"><mat-icon>close</mat-icon></button>
    </div>
  </div>
  <div class="modal-content">
    <div class="p-5">
      <div class="overflow-x-auto shadow-md sm:rounded-lg">
        <div class="inline-block min-w-full align-middle">
          <div class="overflow-hidden">
            <table class="min-w-full divide-y divide-gray-200 table-fixed">
              <thead class="bg-gray-100">
                <tr>
                  <th scope="col"
                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Order ID
                  </th>
                  <th scope="col"
                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Customer Name
                  </th>
                  <th scope="col"
                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Mobile
                  </th>
                  <th scope="col"
                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Gotra
                  </th>
                  <th scope="col"
                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Date & Time
                  </th>
                  <th scope="col"
                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Package
                  </th>
                  <th scope="col"
                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Family Members
                  </th>
                  <th scope="col"
                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Price
                  </th>

                  <th scope="col"
                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Status
                  </th>
                  <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Adress Details
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="userList?.length !== 0; else noData" class="bg-white divide-y divide-gray-200">
                <tr *ngFor="let service of userList" class="hover:bg-gray-100 cursor-pointer">
                  <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                    {{ service?.OrderId }}
                  </td>
                  <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                    {{service?.customerDetail?.firstname}} {{service?.customerDetail?.lastname}}
                  </td>
                  <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                    {{service?.customerDetail?.mobileno}}
                  </td>
                  <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                    {{service?.gotra}}
                  </td>

                  <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                    {{ service?.OrderDate }} {{ service?.OrderTime }}
                  </td>
                  <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                    {{ service?.package }}
                  </td>
                  <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                    {{ service?.familyCount }}
                  </td>
                  <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                    {{ service?.TotalCharges }}
                  </td>
                  <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap" [ngClass]="
                    service?.OrderStatus == 'Success'
                      ? 'text-success-600'
                      : 'text-red-600'
                  ">
                    {{ service?.OrderStatus }}
                  </td> 
                  <td *ngIf="service?.prasadAdd;else noData">
                    {{service?.prasadAdd}}
                  </td>
                </tr>
              </tbody>
              <ng-template #noData>
                <tbody>
                  <tr>
                    <td colspan="12" class="text-center" style="font-size: 14px">
                      No data available
                    </td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
            <div class="p-5 float-right">
              <button (click)="exportexcel()" class="px-3 py-2 rounded-lg bg-blue-700 text-white">
                Export To Excel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
