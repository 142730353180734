import { Component } from '@angular/core';

@Component({
  selector: 'app-content-updates',
  templateUrl: './content-updates.component.html',
  styleUrls: ['./content-updates.component.css']
})
export class ContentUpdatesComponent {

}
