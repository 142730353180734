


<div class="mx-auto">
    <form [formGroup]="customerData">
        <div class="flex justify-center  w-full">
            <div class="grid grid-cols-7 items-center gap-5 w-full">
                <!-- <div class="md:col-auto col-span-12 font-bold text-lg text-blue-600">
                    Manage Customers
                </div> -->
                <!-- <div class="md:col-auto col-span-12 font-bold text-lg">
                </div> -->
                <div class="md:col-auto col-span-12">
                    <input type="text" class=" rounded-xl border-gray-200 py-1 px-2 w-full border-2 " placeholder="Search..."
                    (input)="search($event)">
                </div>

                <div class="md:col-span-1  col-span-12 ">
                    <ng-select [items]="rechargeTimesFilter"  placeholder="Select Recharge Count" bindLabel="name" bindValue="value"
                        formControlName="rechargeTimesFilter" class="w-full text-sm " (change)="rechargeTimesFilterFn()">
                    </ng-select>
                </div>
                <div class="md:col-span-1  col-span-12">
                    <ng-select [items]="freeCallChatVCallFilter"  placeholder="Select Free Call/Chat" bindLabel="name" bindValue="value"
                        formControlName="freeCallChatVCallFilter" class="w-full text-sm" (change)="freeCallChatVCallFilterFn()">
                    </ng-select>
                </div>
                <div class="md:col-span-1  col-span-12">
                    <ng-select [items]="firstPaidCallChatFilter"  placeholder="Select First Paid Call/Chat" bindLabel="name" bindValue="value"
                        formControlName="firstPaidCallChatFilter" class="w-full text-sm" (change)="firstPaidCallChatFilterFn()">
                    </ng-select>
                </div>
                <div class="md:col-span-1  col-span-12">
                    <ng-select [items]="fiftyRsRechargeFilter"  placeholder="Select Recharge Type" bindLabel="name" bindValue="value"
                        formControlName="fiftyRsRechargeFilter" class="w-full text-sm" (change)="fiftyRsRechargeFilterFn()">
                    </ng-select>
                </div>
                <div class="md:col-span-1  col-span-12">
                    <ng-select [items]="otherOptions"  placeholder="Select Customer Status..." bindLabel="name" bindValue="value"
                        formControlName="customerStatus" class="w-full text-sm" (change)="customerStatusFn()">
                    </ng-select>
                </div>
                <div class="md:col-span-1  col-span-12">
                    <ng-select [items]="customerPanelCustomFilter"  placeholder="More Filters.." bindLabel="name" bindValue="value"
                        formControlName="customerPanelCustomFilter" class="w-full text-sm" (change)="customerPanelCustomFilterFn()">
                    </ng-select>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="my-5 ">
    <div class="flex flex-col">
        <div class="overflow-x-auto shadow-md sm:rounded-lg">
            <div class="inline-block min-w-full align-middle">
                <div class="overflow-hidden ">
                    <table class="min-w-full divide-y divide-gray-200 table-fixed ">
                        <thead class="bg-gray-100 ">
                            <tr>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    First Name</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Last Name</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Gender </th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    DOB</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Birth Time</th>
                                <!-- <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Country</th> -->
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    MobileNo</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Status</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    EntryDate</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    App Version</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    App Name</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="customerList?.customerDataList?.length!==0;else noData"
                            class=" bg-white divide-y divide-gray-200 text-center">
                            <tr *ngFor="let item of customerList?.customerDataList" class="hover:bg-gray-100">
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.FirstName ? item?.FirstName : "N/A"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.LastName ? item?.LastName : "N/A"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.Gender ? item?.Gender : "N/A"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.DOB ? item?.DOB : "N/A"}}
                                </td>
                                <!-- <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.BirthTime}}
                                </td> -->
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.BirthTime ? item?.BirthTime : "N/A"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.MobileNo ? item?.MobileNo : "N/A"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left"
                                    [ngClass]="item?.Status?'text-green-500':'text-red-500'">
                                    {{item?.Status?"Active":"InActive"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.EntryDate ? item?.EntryDate : "N/A"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-blue-500 whitespace-nowrap text-left">
                                    {{item?.appVersion ? item?.appVersion : "N/A"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-blue-500 whitespace-nowrap text-left">
                                    {{item?.versionCode ? item?.versionCode : "N/A"}}
                                </td>
                                <td role="link" class="py-4 px-6 text-sm font-medium whitespace-nowrap text-left">
                                    <a mat-menu-item routerLink="../customer-view/customer-profile" 
                                        [queryParams]="{id:item?.CustomerId}" 
                                        class="" target="_blank"
                                    >
                                        View
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                        <ng-template #noData>
                            <tbody>
                                <td colspan="12"
                                    class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap pl-[50px]"
                                    style="font-size: 14px">
                                    No data
                                </td>
                            </tbody>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="flex justify-between p-2">
    <div>
        <input #titleInput class="border-gray-200 p-1 border-2 w-20" placeholder="Page No." (keydown.enter)="handleGoto(titleInput.value)">
        <button (click)="handleGoto(titleInput.value)"
            class="border-gray-200 p-1 border-2 bg-slate-300 hover:bg-slate-500">Go</button>
    </div>
    <div>
        <mat-paginator [length]="customerList?.totalPage" [pageSize]="1" hidePageSize="true" onchange="fetchCustomers()"
            (page)="pagination($event)" [pageIndex]=" this.customerData.get('page')?.value-1">
        </mat-paginator>
    </div>

</div>


<div class="flex justify-center  w-full">
    <div class="grid grid-cols-4 items-center gap-2 w-full text-base font-bold">
        <div class="md:col-span-1 col-span-12 text-green-600">
            <div>Total Active Cusotmers</div>
            <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg  block p-2 border-gray-600">
                {{customerList?.totalActiveCustomers}}
            </div>
        </div>
        <div class="md:col-span-1  col-span-12 text-red-600">
            <div>Total InActive Customers</div>
            <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg  block p-2 border-gray-600">
                {{customerList?.inactiveCustomers}}
            </div>
        </div>
        <div class="md:col-span-1 col-span-12 mt-4">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-2 rounded" (click)="exportexcel()">
              Export to Excel
            </button>
          </div>
    </div>
</div>


  