<h1 class="mb-5 font-semibold text-blue-600">Datewise Call/Chat/VideoCall Count</h1>
<div class="mx-auto">


  <div class="flex flex-col">
    <div class="overflow-x-auto shadow-md sm:rounded-lg">
      <div class="inline-block min-w-full align-middle">
        <div class="overflow-hidden">
          <table class="min-w-full divide-y divide-gray-200 table-fixed">
            <thead class="bg-gray-100">
              <tr>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                  Date
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    completed chat
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    missed chat
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Inprogress chat
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Refunded chat
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Cancelled chat
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    NotResponded chat
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    completed call
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    missed call
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Inprogress call
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Refunded call
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Cancelled call
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    NotResponded call
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    totaL_promotional call
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    completed vcall
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    missed vcall
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Inprogress vcall
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Refunded vcall
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    Cancelled vcall
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                    NotResponded vcall
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                   Company Amount
                </th>
              </tr>
            </thead>
            <tbody *ngIf="logDataList?.data?.length !== 0; else noData"
              class="bg-white divide-y divide-gray-200">
              <tr *ngFor="let item of logDataList?.data" class="hover:bg-gray-100 cursor-pointer">
                <td 
                  class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                  {{ item?.start_time }}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                  {{ item?.completed_chat }}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap">
                  {{ item?.missed_chat }}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{ item?.Inprogress_chat }}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{ item?.Refunded_chat }}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{ item?.Cancelled_chat }}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{ item?.NotResponded_chat}}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {{ item?.completed_call}}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {{ item?.missed_call}}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {{ item?.Inprogress_call}}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {{ item?.Refunded_call}}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {{ item?.Cancelled_call}}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {{ item?.NotResponded_call}}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {{ item?.totaL_promotional_call}}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {{ item?.completed_vcall}}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {{ item?.missed_vcall}}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {{ item?.Inprogress_vcall}}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {{ item?.Refunded_vcall}}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap"> 
                    {{ item?.Cancelled_vcall}}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {{ item?.NotResponded_vcall}}
                </td>
                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{ item?.totalEarning ? item?.totalEarning : "N/A"}}
              </td>
              </tr>
            </tbody>
            <ng-template #noData>
              <tbody>
                <tr>
                  <td colspan="12" class="text-center" style="font-size: 14px">
                    No data available
                  </td>
                </tr>
              </tbody>
            </ng-template>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="flex justify-between p-2">
    <div>
        <input #titleInput class="border-gray-200 p-1 border-2 w-20" placeholder="Page No." (keydown.enter)="handleGoto(titleInput.value)">
        <button (click)="handleGoto(titleInput.value)"
            class="border-gray-200 p-1 border-2 bg-slate-300 hover:bg-slate-500">Go</button>
    </div>
    <div>
        <mat-paginator [length]="logDataList?.totalPage" [pageSize]="1" hidePageSize="true" onchange="getLogsData()" pageIndex={{pageRef}}
            (page)="onPaginateChange($event)">
        </mat-paginator>

    </div>
</div>

<!-- <div class="flex justify-center w-full">
  <div class="grid grid-cols-8 items-center gap-1 w-full text-xs">
    <div class="md:col-span-1 col-span-12">
      <div>Total Entry</div>
      <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
        {{ accountSummary?.totalEntry }}
      </div>
    </div>
    <div class="md:col-span-1 col-span-12">
      <div>Total Charges</div>
      <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
        {{ accountSummary?.totalCharges }}
      </div>
    </div>
    <div class="md:col-span-1 col-span-12">
      <div>Total AC Share</div>
      <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
        {{ accountSummary?.acShares }}
      </div>
    </div>
    <div class="md:col-span-1 col-span-12">
      <div>Total Astro Share</div>
      <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
        {{ accountSummary?.astroShares }}
      </div>
    </div>
    <div class="md:col-span-1 col-span-12">
      <div>Total PGAmt</div>
      <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
        {{ accountSummary?.totalPgamt }}
      </div>
    </div>
    <div class="md:col-span-1 col-span-12">
      <div>Total TDSAmt</div>
      <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
        {{ accountSummary?.totalTdsamt }}
      </div>
    </div>
    <div class="md:col-span-1 col-span-12">
      <div>Total Net Amount</div>
      <div class="bg-gray-50 border text-gray-900 text-sm rounded-lg block p-2 border-gray-600">
        {{ accountSummary?.totalNetamt }}
      </div>
    </div>
    <div class="md:col-span-1 col-span-12 mt-4">
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-2 rounded" (click)="exportexcel()">
        Export to Excel
      </button>
    </div>
  </div>
</div> -->

<!-- <ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Call ID: {{ modalData?.order?.id }}
    </h4>
  </div>
  <div class="modal-content">
    <div class="p-2">
      <table class="table-auto text-center">
        <tr>
          <th class="border-r-2">Call Date</th>
          <td>{{ modalData?.order?.orderInitiate | date : "longDate" }}</td>
        </tr>
        <tr>
          <th class="border-r-2">Call Time</th>
          <td>{{ modalData?.order?.orderInitiate | date : "shortTime" }}</td>
        </tr>

        <tr>
          <th class="border-r-2">Customer Name</th>
          <td>
            {{
            modalData?.customerInfo?.firstname +
            " " +
            modalData?.customerInfo?.lastname
            }}
          </td>
        </tr>
        <tr>
          <th class="border-r-2">Mobile</th>
          <td>{{ modalData?.customerInfo?.mobileno }}</td>
        </tr>
        <tr>
          <th class="border-r-2">Astrologer Name</th>
          <td>{{ modalData?.astroname }}</td>
        </tr>
        <tr>
          <th class="border-r-2">Call Status</th>
          <td [ngClass]="
              modalData?.order?.orderStatus == 'Completed'
                ? 'text-green-500'
                : modalData?.order?.orderStatus == 'Cancelled'
                ? 'text-red-500'
                : modalData?.order?.orderStatus == 'Refunded'
                ? 'text-blue-500'
                : 'text-yellow-500'
            ">
            {{ modalData?.order?.orderStatus }}
          </td>
        </tr>
        <tr>
          <th class="border-r-2">Call Rate</th>
          <td>{{ modalData?.charges?.astroRate }}</td>
        </tr>
        <tr>
          <th class="border-r-2">Call Duration</th>
          <td>{{ modalData?.order?.orderDuration }}</td>
        </tr>
        <tr>
          <th class="border-r-2">Total Charges</th>
          <td>{{ modalData?.charges?.totalCharges }}</td>
        </tr>
        <tr>
          <th class="border-r-2">Company Share</th>
          <td>{{ modalData?.charges?.compAmt }}</td>
        </tr>
        <tr>
          <th class="border-r-2">Astrologer Share</th>
          <td>{{ modalData?.charges?.astroAmt }}</td>
        </tr>
        <tr>
          <th class="border-r-2">PG(%)</th>
          <td>{{ modalData?.charges?.pgPer }}</td>
        </tr>
        <tr>
          <th class="border-r-2">PG Amount</th>
          <td>{{ modalData?.charges?.pgAmt }}</td>
        </tr>
        <tr>
          <th class="border-r-2">TDS(%)</th>
          <td>{{ modalData?.charges?.TDSPer }}</td>
        </tr>
        <tr>
          <th class="border-r-2">TDS Amount</th>
          <td>{{ modalData?.charges?.TDSAmt }}</td>
        </tr>
        <tr>
          <th class="border-r-2">Astrologer Net Share</th>
          <td>{{ modalData?.charges?.astroTotalAmt }}</td>
        </tr>
        <tr>
          <th class="border-r-2">Remarks</th>
          <td>
            {{
            modalData?.order?.remarks
            ? modalData?.order?.remarks
            : "No Remarks"
            }}
          </td>
        </tr>
        <tr>
          <th class="border-r-2">Recording URL</th>
          <td *ngIf="modalData?.order?.recordinURL; else noData">
            <a href="{{ modalData?.order?.recordinURL }}" target="_blank"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 w-2 rounded text-xs">View Recording</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</ng-template> -->

<!-- <ng-template #template1>
  <div class="modal-header flex justify-between p-0 m-0">
    <div>
      <p class="font-bold text-lg">Add Product</p>
    </div>
    <div class="float-right">
      <button (click)="closeModal()"><mat-icon>close</mat-icon></button>
    </div>
  </div>
  <div class="modal-content">
    <div class="p-2">
      <form [formGroup]="refundData">
        <div *ngIf="!isOTP" class="relative z-0 my-6 w-full">
          <div class="col-span-12 mb-6 w-full">
            <ng-select class="" [items]="refundReason" placeholder="Reason" bindLabel="name" bindValue="value"
              formControlName="reason" class="w-full">
            </ng-select>
          </div>
          <div class="col-span-12 relative z-0 mb-6 w-full">
            <textarea type="text" name="category_name"
              class="block h-32 py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-2 rounded-md border-gray-300 dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" " cols="3" formControlName="message"></textarea>
            <label for="category_name"
              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 z-0 peer-focus:z-0 px-2 peer-placeholder-shown:-z-10 bg-white peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
              Message
            </label>
          </div>
        </div>
        <div class="flex justify-center mb-2">
          <ng-otp-input (onInputChange)="onOtpChange($event)" *ngIf="isOTP" [config]="{ length: 6 }"></ng-otp-input>
        </div>
        <button *ngIf="!isOTP" type="submit" (click)="sendOTP()"
          class="text-white float-right bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          Send OTP
        </button>

        <button *ngIf="isOTP" type="submit" (click)="submitRefund()"
          class="text-white float-right bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          Submit Refund
        </button>

      </form>
    </div>
  </div>
</ng-template> -->