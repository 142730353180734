import { Component } from '@angular/core';

@Component({
  selector: 'app-mng-customers',
  templateUrl: './mng-customers.component.html',
  styleUrls: ['./mng-customers.component.css']
})
export class MngCustomersComponent {
 
}
